import React, { Component } from "react";
// import { Container, Row, Col } from "reactstrap";
import logo from "../assets/images/logo-stryker.png";

class NavBar extends Component {
    goBack = () => {
        console.log("go back.");
        window.history.back();
    };
    render () {
        return (
            <div className="tab-navbar">
                <div className="back" onClick={ this.goBack } >
                    <i className="las la-angle-left" />
      BACK
    </div>
                <div className="navbar logo-stryker">
                    <img src={ logo } alt="logo" />

                </div>
            </div>
        );
    }
}

export default NavBar;

