import React, { useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
	getRespondentsForSurveyAction,
	getSurveyTemplateAction,
	submitSurveyResponses
} from '../Store/actions/surveysAction';
import {
	getRespondentsForEvaluationAction,
	getEvaluationTemplateAction,
	submitEvaluationResponses
} from '../Store/actions/evaluationsAction';
import FormQuestions from './Trial/SurveysAndEvaluations/FormQuestions/FormQuestions';

const TrialsFilloutRespondentSurveyEval = (props) => {
	const { type, trialID, id, personID, personType } = props.match.params;
	const singularType = type.endsWith('s') ? type.substring(0, type.length - 1) : type;
	const { getEvaluation, getSurvey, getEvaluationRespondents, getSurveyRespondents } = props;
	const selectedTemplate = props[singularType];
	const selectedPerson = props[`${type}Respondents`]?.find(person => person.id === personID);
	let sigCanvas = useRef(null);
	const [ signatureModalOpen, setSignatureModalOpen ] = useState(false);
	if (!selectedTemplate || selectedTemplate.template_id === '') {
		if (type === 'survey') {
			getSurvey({ trialID: trialID, templateID: id });
			getSurveyRespondents({ trialID: trialID, templateID: id });
		} else {
			getEvaluation({ trialID: trialID, templateID: id });
			getEvaluationRespondents({ trialID: trialID, templateID: id });
		}
	}

	const clear = () => {
		sigCanvas.clear();
	};

	const [ answers, setAnswers ] = useState([]);
	const [ submissionErrors, setSubmissionErrors] = useState([]);

	const validateAnswers = (submittedAnswers) => {
		let answersAreValid = true;
		const errors = [];
		selectedTemplate.question_list.forEach(question => {
			if (question.is_required === '1') {
				const answer = submittedAnswers.find(a => a.questionID === question.id);
				if(!answer || answer.selectedOptions.length === 0) {
					answersAreValid = false;
					errors.push(question.id);
				}
			}
		});
		setSubmissionErrors(errors);
		if(answersAreValid) {
			setAnswers(submittedAnswers);
			setSignatureModalOpen(true);
		} else {
			
		}
	};
	function dataURLtoBlob(dataurl) {
		let arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([ u8arr ], { type: mime });
	}

	const submitAnswers = () => {
		const signatureImage = sigCanvas.getTrimmedCanvas().toDataURL('image/png');
		const signatureFile = dataURLtoBlob(signatureImage);
		const parameters = {
			trialID: trialID,
			templateID: selectedTemplate.template_id,
			peopleID: personID,
			peopleType: personType,
			filloutBy: 'me',
			signature: signatureFile,
			name: selectedTemplate.name,
			evaluationData: answers.map((ans) => {
				return {
					question_id: ans.questionID,
					question_option_id: ans.selectedOptions
				};
			})
		};

		type === 'survey' ? props.submitSurvey(parameters, showThankyouPage) : props.submitEvaluation(parameters,showThankyouPage);
	};
	const [ thankYouOpen, setThankyouOpen ] = useState(false);
	const showThankyouPage = () => {
		setThankyouOpen(true);
	};

	return (
		<React.Fragment>
			<Container className="page pb-80" style={{ background: 'white' }}>
				<Row className="tab-title-main">
					<Col>
						<h4 className="section-title-survey">{selectedTemplate.template_name} </h4>

						<div className="title-user">
							{selectedPerson && (
								<div key={selectedPerson.id} className="person-details-container">
									<div className="bubble-name">
										{' '}
										{selectedPerson.name.split(' ')[0].charAt(0)}
										{ selectedPerson.name.split(' ')[1]?.charAt(0) }
									</div>
									<div className="file-details user-survey">
										<div key={selectedPerson.id} value={selectedPerson.name}>
											<ul className="">
												<li>{selectedPerson.name}</li>
												<li className="subtitle-tiny">{selectedPerson.type}</li>
											</ul>
										</div>
									</div>
								</div>
							)}
						</div>
					</Col>
				</Row>
				<br />
				<FormQuestions questionsArray={selectedTemplate.question_list} submitAnswers={validateAnswers} errors={submissionErrors}/>
				{submissionErrors.length > 0 && <p className='form_error_msg'>Fields are required</p>}
				<Modal
					className="signatureModal"
					isOpen={signatureModalOpen}
					toggle={() => setSignatureModalOpen(true)}
					style={{ margin: '0 auto', maxWidth: 900 }}
					backdrop="static"
				>
					{!thankYouOpen ? (
						<div>
							<div className="signature__details">
                                <i className="closeModal las la-times" onClick={() => setSignatureModalOpen(false)}></i>
								<h2>{selectedTemplate.template_name}</h2>
								<p>{selectedPerson && selectedPerson.name}</p>
							</div>
							<ModalBody style={{ padding: 0 }}>
								<SignatureCanvas
									penColor="black"
									canvasProps={{
										width:
											window.innerHeight > window.innerWidth
												? window.innerWidth
												: window.innerHeight,
										height:
											window.innerWidth > window.innerHeight
												? window.innerHeight - 10
												: window.innerWidth - 10,
										className: 'sigCanvas'
									}}
									ref={(ref) => {
										sigCanvas = ref;
									}}
								/>
							</ModalBody>
							<div className="signature__actions">
								<button className="sykButtonOutline" onClick={clear}>
									Reset Signature
								</button>
								<button className="sykButton" onClick={submitAnswers}>
									Submit
								</button>
							</div>
						</div>
					) : (
						<div>
							<ModalBody style={{ padding: 0 }}>
								<div className="thankyoumodal">
									<div className="thankyou">
										<h1>
											<span className="sykyellow">Thank you</span>
											<br />
											<span className="egyptiennelig">
												for completing<br />our survey!
											</span>
										</h1>
										<button
											className="sykButtonOuline"
											onClick={() =>
												window.location.replace(`/trial/${trialID}/surveyevals/${type}/${id}`)}
										>
											CONTINUE
										</button>
									</div>
									<div class="goldGradientBar" />
                                    <div>
                                        <p className="returnDevice">Please return this device<br/>to your <span className="humanistbtblack">sales representative.</span></p>
                                    </div>
								</div>
							</ModalBody>
						</div>
					)}
				</Modal>
			</Container>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		surveyRespondents: state.surveys.surveysRespondents,
		evaluationsRespondents: state.evaluations.evaluationsRespondents,
		evaluation: state.evaluations.selectedTemplate,
		survey: state.surveys.selectedTemplate,
		surveySubmittedSuccess: state.surveys.surveySubmittedSuccess,
		evaluationSubmittedSuccess: state.evaluations.evaluationSubmittedSuccess
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvaluation: (parameters) => {
			dispatch(getEvaluationTemplateAction(parameters));
		},
		getSurvey: (parameters) => {
			dispatch(getSurveyTemplateAction(parameters));
		},

		getEvaluationRespondents: (parameters) => {
			dispatch(getRespondentsForEvaluationAction(parameters));
		},
		getSurveyRespondents: (parameters) => {
			dispatch(getRespondentsForSurveyAction(parameters));
		},

		submitSurvey: (parameters, cb) => {
			dispatch(submitSurveyResponses(parameters, cb));
		},
		submitEvaluation: (parameters, cb) => {
			dispatch(submitEvaluationResponses(parameters, cb));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialsFilloutRespondentSurveyEval));
