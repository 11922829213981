import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
	getChecklistsAction,
	putChecklistItemAction,
	putChecklistsItemStatus,
	deleteChecklistAction
} from '../../../Store/actions/checklistsAction';
import { Modal } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import classes from './TrialChecklist.module.css';

import AddChecklist from './AddChecklist/AddChecklist';
import EditChecklist from './EditChecklist/EditChecklist';
import AddChecklistItem from './AddChecklistItem/AddChecklistItem';
import DeleteChecklist from './DeleteChecklist/DeleteChecklist';
import PopoverButton from '../../../Components/Popups/PopoverEllipsis';
import ComponentLoading from '../../../Components/ComponentLoading/ComponentLoading';
import NoResults from '../../../Components/NoResults/NoResults';
import CloseModalButton from '../../../Components/CloseModalButton/CloseModalButton';

class TrialChecklist extends Component {
	state = {
		isAddChecklistModalOpen: false,
		isEditChecklistModalOpen: false,
		isAddChecklistItemModalOpen: false,
		isDeleteChecklistModalOpen: false,
		focusedGroup: {},
		popoverOpen: false,
		searchValue: ''
	};

	componentDidMount() {
		const trialID = this.props.match.params.id;
		this.props.getChecklists(trialID);
	}

	chooseDueDate = (date, checklist, item) => {
		const trialID = this.props.match.params.id;
		const dueDateObject = new Date(date);
		const dueDate = `${dueDateObject.getMonth() + 1} - ${dueDateObject.getDate()} - ${dueDateObject.getFullYear()}`;
		const checklistItemParams = {
			trialID: trialID,
			title: item.title,
			groupID: checklist.id,
			itemID: item.id,
			description: item.description,
			dueDate: dueDate
		};
		this.props.updateChecklistItem(checklistItemParams);
	};

	toggleAddChecklistModal = () => {
		this.setState({ isAddChecklistModalOpen: !this.state.isAddChecklistModalOpen });
	};
	toggleEditChecklistModal = (groupName, groupId) => {
		this.setState({
			isEditChecklistModalOpen: !this.state.isEditChecklistModalOpen,
			focusedGroup: !this.state.isEditChecklistModalOpen ? { name: groupName, id: groupId } : { name: '', id: 0 }
		});
	};
	toggleAddChecklistItemModal = (groupId) => {
		this.setState({
			isAddChecklistItemModalOpen: !this.state.isAddChecklistItemModalOpen,
			focusedGroup: !this.state.isAddChecklistItemModalOpen ? { id: groupId } : { id: 0 }
		});
	};

	toggleDeleteChecklistModal = (groupId) => {
		this.setState({
			isDeleteChecklistModalOpen: !this.state.isDeleteChecklistModalOpen,
			focusedGroup: !this.state.isDeleteChecklistModalOpen ? { id: groupId } : { id: 0 }
		});
	};

	handleInputChange = (event) => {
		this.setState({
			searchValue: event.currentTarget.value
		});
	};

	filterChecklistBySearch = () => {
		let cloneChecklists = JSON.parse(JSON.stringify(this.props.checklists));

		let noResultsWhenFilteringBySearch = true;

		for (let i = 0; i < cloneChecklists.length; i++) {
			cloneChecklists[i].item = cloneChecklists[i].item.filter((item) => {
				return item.title.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});

			if (cloneChecklists[i].item.length > 0) {
				noResultsWhenFilteringBySearch = false;
			}
		}

		return [ cloneChecklists, noResultsWhenFilteringBySearch ];
	};

	render() {
		const { focusedGroup } = this.state;
		const trialID = this.props.match.params.id;

		if (this.props.isFetchingGetChecklists) {
			return <ComponentLoading message="Loading..." isFullHeight={true} />;
		}

		const [ filterChecklistBySearch, noResultsWhenFilteringBySearch ] = this.filterChecklistBySearch();
		console.log(filterChecklistBySearch);
		return (
			<div className={classes['checklists']}>
				<div className={classes['checklist__title-container']}>
					<h1 className={classes['checklist__title']}>Trial checklist</h1>
					<button onClick={this.toggleAddChecklistModal} className={classes['checklist__add-button']}>
						<i className="las la-plus" />{' '}
					</button>
				</div>
				<div className={classes['checklist__search-container']}>
					<i className="las la-search" />
					<input
						value={this.state.searchValue}
						onChange={this.handleInputChange}
						className={classes['checklist__search-input']}
					/>
				</div>
				<div className={classes['checklist-container']}>
					{filterChecklistBySearch &&
						filterChecklistBySearch.map((checklist, ci) => {
							/* if (checklist.item.length === 0) {
								return null;
							} */

							return (
								<div key={checklist.id}>
									<div className={classes['checklist__category-title-container']}>
										<p className={classes['checklist_category-title']}>{checklist.name}</p>
										<PopoverButton
											itemId={ci}
											onEdit={() => this.toggleEditChecklistModal(checklist.name, checklist.id)}
											onDelete={() => this.toggleDeleteChecklistModal(checklist.id)}
										/>
									</div>
									{checklist.item.map((item) => {
										return item.is_completed == '0' ? (
											<div key={item.id} className={classes['checklist']}>
												<div
													className={classes['circle']}
													onClick={() => {
														this.props.toggleChecklistItem({
															trialID: trialID,
															itemID: item.id,
															isCompleted: item.is_completed === '0' ? '1' : '0'
														});
													}}
												/>

												<div className={classes['checklist__wrapper']}>
													<div className={classes['checklist__item']}>
														<Link to={`${item.id}`} key={item.id}>
															<p className={classes['checklist__item-title']}>
																{item.title}
															</p>

															<p className={classes['checklist__text']}>
																{item.description}
															</p>
														</Link>

														<div className={classes['checklist__indicators']}>
															<div className={classes['checklist__comments']}>
																<i className="lar la-comment-alt" /> {' '}
																{item.total_comments}
															</div>
															<DatePicker
																type="date"
																className={classes['checklist__due-date-input']}
																placeholderText="Enter a due date"
																onChange={(date) =>
																	this.chooseDueDate(date, checklist, item)}
																selected={
																	item.due_date ? (
																		new Date(
																			item.due_date
																				.replaceAll(/ /g, '')
																				.replaceAll(/-/g, '/')
																		)
																	) : (
																		''
																	)
																}
																popperPlacement="bottom-start"
																popperModifiers={{
																	offset: {
																		enabled: true,
																		offset: '-125px, 10px'
																	},
																	preventOverflow: {
																		enabled: true,
																		escapeWithReference: false,
																		boundariesElement: 'viewport'
																	}
																}}
															/>
														</div>
													</div>
													<Link to={`${item.id}`} key={item.id}>
														<i className="las la-angle-right" />
													</Link>
												</div>
											</div>
										) : (
											<div key={item.id} className={classes['checklist']}>
												<div
													className={classes['circle__completed']}
													onClick={() => {
														this.props.toggleChecklistItem({
															trialID: trialID,
															itemID: item.id,
															isCompleted: item.is_completed === '0' ? '1' : '0'
														});
													}}
												>
													<i className="las la-check" />
												</div>

												<Link
													to={`${item.id}`}
													className={classes['checklist__wrapper']}
													key={item.id}
												>
													<div className={classes['checklist__item']}>
														<div>
															<p className={classes['checklist__item-title']}>
																{item.title}
															</p>

															<p className={classes['checklist__text']}>
																{item.description}
															</p>
														</div>

														<div className={classes['checklist__indicators']}>
															<p className={classes['checklist__due-date']}>
																Completed on:<br />
																{item.completed_date}
															</p>
														</div>
													</div>
													<div>
														<i className="las la-angle-right" />
													</div>
												</Link>
											</div>
										);
									})}

									<div className={classes['checklist__add-new-item-container']}>
										<button
											className={classes['checklist__add-new-item']}
											onClick={() => this.toggleAddChecklistItemModal(checklist.id)}
										>
											ADD NEW ACTION ITEM
										</button>
									</div>
								</div>
							);
						})}
				</div>
				<Modal
					centered
					isOpen={this.state.isAddChecklistModalOpen}
					toggle={this.toggleAddChecklistModal}
					backdrop="static"
				>
					<CloseModalButton onClick={this.toggleAddChecklistModal} />

					<AddChecklist toggleModal={this.toggleAddChecklistModal} trialID={this.props.match.params.id} />
				</Modal>

				<Modal
					centered
					isOpen={this.state.isEditChecklistModalOpen}
					toggle={this.toggleEditChecklistModal}
					backdrop="static"
				>
					<CloseModalButton onClick={this.toggleEditChecklistModal} />

					<EditChecklist
						toggleModal={this.toggleEditChecklistModal}
						trialID={this.props.match.params.id}
						checklist={focusedGroup}
					/>
				</Modal>

				<Modal
					centered
					isOpen={this.state.isAddChecklistItemModalOpen}
					toggle={this.toggleAddChecklistItemModal}
					backdrop="static"
				>
					<CloseModalButton onClick={this.toggleAddChecklistItemModal} />

					<AddChecklistItem
						toggleModal={this.toggleAddChecklistItemModal}
						trialID={this.props.match.params.id}
						checklist={focusedGroup}
					/>
				</Modal>
				<Modal
					centered
					isOpen={this.state.isDeleteChecklistModalOpen}
					toggle={this.toggleDeleteChecklistModal}
					backdrop="static"
				>
					<CloseModalButton onClick={this.toggleDeleteChecklistModal} />

					<DeleteChecklist
						toggleModal={this.toggleDeleteChecklistModal}
						trialID={this.props.match.params.id}
						checklist={focusedGroup}
					/>
				</Modal>

				{this.props.checklists.length === 0 && !this.props.isFetchingGetChecklists ? <NoResults /> : null}

				{noResultsWhenFilteringBySearch && this.props.checklists.length !== 0 ? <NoResults /> : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		checklists: state.checklists.getChecklists,
		isFetchingGetChecklists: state.checklists.isFetchingGetChecklists
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getChecklists: (trialID) => {
			dispatch(getChecklistsAction(trialID));
		},
		updateChecklistItem: (updateParams) => {
			dispatch(putChecklistItemAction(updateParams));
		},
		deleteChecklist: (updateParams) => {
			dispatch(deleteChecklistAction(updateParams));
		},

		toggleChecklistItem: (updateParams) => {
			dispatch(putChecklistsItemStatus(updateParams));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialChecklist));
