import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	isFetchingEvaluations: false,
	evaluations: [],
	evaluationsPage: 1,
	evaluationsPageLimitReached: false,
	evaluationsError: '',

	isFetchingEvaluationTemplates: false,
	templates: [],
	templatesPage: 1,
	templatesPageLimitReached: false,
	templatesError: '',

	isFetchingEvaluationCrushTemplates: false,
	crushTemplates: [],
	crushTemplatesPage: 1,
	crushTemplatesPageLimitReached: false,
	crushTemplatesError: '',

	evaluationResults: [],
	evaluationResultsPage: 1,
	evaluationResultsPageLimitReached: false,
	evaluationResultsError: '',

	createEvaluationsTemplateName: {},
	createEvaluationsTemplateNameError: '',

	createEvaluationsQuestionError: '',
	duplicateEvaluationError: '',

	evaluationsRespondents: [],
	evaluationsRespondentsError: '',

	selectedTemplate: {
		active_for_fillout: '',
		created_by: '',
		created_by_id: '',
		created_date: '',
		crush_template_id: '',
		last_taken: '',
		ordering: '',
		question_list: [],
		respondents: '',
		template_id: '',
		template_name: 'LOADING',
		trial_id: '',
		updated_date: ''
	},
	selectedTemplateError: {},

	completedEvaluation: {},
	completedEvaluationError: '',

	evaluationResultSummary: {},
	evaluationResultSummaryError: ''
};

const evaluationsReducer = (state = initState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_GET_EVALUATIONS:
			return {
				...state,
				isFetchingEvaluations: true
			};

		case ActionTypes.GET_EVALUATIONS_SUCCESS:
			let evaluations = state.evaluations;
			if (
				(action.evaluationsPage > state.evaluationsPage || action.evaluationsPageLimitReached) &&
				action.evaluations.length > 0
			) {
				evaluations = [ ...evaluations, ...action.evaluations ];
			} else if (action.evaluationsPage === 1 && action.evaluationsPageLimitReached) {
				evaluations = action.evaluations;
			}
			return {
				...state,
				evaluations: action.evaluations,
				evaluationsPage: action.evaluationsPage,
				evaluationsPageLimitReached: action.evaluationsPageLimitReached,
				evaluationsError: '',
				isFetchingEvaluations: false
			};

		case ActionTypes.GET_EVALUATIONS_ERROR:
			return {
				...state,
				evaluationsError: action.evaluationsError,
				isFetchingEvaluations: false
			};

		case ActionTypes.REQUEST_GET_EVALUATION_TEMPLATES:
			return {
				...state,
				isFetchingEvaluationTemplates: true
			};

		case ActionTypes.GET_EVALUATION_TEMPLATES_SUCCESS:
			let templates = state.templates;
			if (
				(action.templatesPage > state.templatesPage || action.templatesPageLimitReached) &&
				action.templates.length > 0
			) {
				templates = [ ...state.templates, ...action.templates ];
			} else if (action.templatesPage === 1 && action.templatesPageLimitReached) {
				templates = action.templates;
			}
			return {
				...state,
				templates: templates,
				templatesPage: action.templatesPage,
				templatesPageLimitReached: action.templatesPageLimitReached,
				templatesError: '',
				isFetchingEvaluationTemplates: false
			};

		case ActionTypes.GET_EVALUATION_TEMPLATES_ERROR:
			return {
				...state,
				templatesError: action.templatesError,
				templates: [],
				isFetchingEvaluationTemplates: false
			};

		case ActionTypes.REQUEST_GET_EVALUATION_CRUSH_TEMPLATES:
			return {
				...state,
				isFetchingEvaluationCrushTemplates: true
			};

		case ActionTypes.GET_EVALUATION_CRUSH_TEMPLATES_SUCCESS:
			let crushTemplates = state.crushTemplates;
			if (
				(action.crushTemplatesPage > state.crushTemplatesPage || action.crushTemplatesPageLimitReached) &&
				action.crushTemplates.length > 0
			) {
				crushTemplates = [ ...crushTemplates, ...action.crushTemplates ];
			} else if (action.crushTemplatesPage === 1 && action.crushTemplatesPageLimitReached) {
				crushTemplates = action.crushTemplates;
			}
			return {
				...state,
				crushTemplates: crushTemplates,
				crushTemplatesPage: action.crushTemplatesPage,
				crushTemplatesPageLimitReached: action.crushTemplatesPageLimitReached,
				crushTemplatesError: '',
				isFetchingEvaluationCrushTemplates: false
			};

		case ActionTypes.GET_EVALUATION_CRUSH_TEMPLATES_ERROR:
			return {
				...state,
				crushTemplatesError: action.crushTemplatesError,
				crushTemplates: [],
				isFetchingEvaluationCrushTemplates: false
			};
		case ActionTypes.GET_EVALUATION_RESULTS_SUCCESS:
			let evaluationResults = state.evaluationResults;
			if (action.page > state.evaluationResultsPage && action.evaluationResults.length > 0) {
				evaluationResults = [ ...evaluationResults, ...action.evaluationResults ];
			}
			return {
				...state,
				evaluationResults: evaluationResults,
				evaluationResultsPage: action.page,
				evaluationResultsPageLimitReached: action.limitReached,
				evaluationResultsError: ''
			};

		case ActionTypes.GET_EVALUATION_RESULTS_ERROR:
			return {
				...state,
				evaluationResultsError: action.evaluationsError
			};

		case ActionTypes.CREATE_EVALUATIONS_TEMPLATE_NAME_SUCCESS:
			return {
				...state,
				createEvaluationsTemplateName: action.createEvaluationsTemplateName,
				createEvaluationsTemplateNameError: ''
			};

		case ActionTypes.CREATE_EVALUATIONS_TEMPLATE_NAME_ERROR:
			return {
				...state,
				createEvaluationsTemplateNameError: action.createEvaluationsTemplateNameError
			};

		case ActionTypes.CREATE_EVALUATIONS_QUESTION_SUCCESS:
			return {
				...state,
				createEvaluationsQuestionError: ''
			};

		case ActionTypes.CREATE_EVALUATIONS_QUESTION_ERROR:
			return {
				...state,
				createEvaluationsQuestionError: action.createSurveysAndEvaluationsQuestionError
			};

		case ActionTypes.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				templates: action.templates,
				templatesError: ''
			};

		case ActionTypes.GET_TEMPLATES_ERROR:
			return {
				...state,
				templatesError: action.templatesError,
				templates: []
			};
		case ActionTypes.GET_EVALUATION_TEMPLATE_SUCCESS:
			return {
				...state,
				selectedTemplate: action.selectedTemplate,
				selectedTemplateError: ''
			};

		case ActionTypes.GET_EVALUATION_TEMPLATE_ERROR:
			return {
				...state,
				selectedTemplateError: action.selectedTemplateError,
				selectedTemplate: []
			};

		case ActionTypes.GET_EVALUATIONS_RESPONDENTS_SUCCESS:
			return {
				...state,
				evaluationsRespondents: action.evaluationsRespondents,
				evaluationsRespondentsError: ''
			};

		case ActionTypes.GET_EVALUATIONS_RESPONDENTS_ERROR:
			return {
				...state,
				evaluationsRespondentsError: action.evaluationsRespondentsError
			};

		case ActionTypes.DUPLICATE_EVALUATIONS_SUCCESS:
			return {
				...state,
				duplicateEvaluationError: ''
			};

		case ActionTypes.DUPLICATE_EVALUATIONS_ERROR:
			return {
				...state,
				duplicateEvaluationError: action.duplicateEvaluationError
			};

		case ActionTypes.GET_COMPLETED_EVALUATION_SUCCESS:
			return {
				...state,
				completedEvaluation: action.completedEvaluation,
				completedEvaluationError: ''
			};

		case ActionTypes.GET_COMPLETED_EVALUATION_ERROR:
			return {
				...state,
				completedEvaluation: {},
				completedEvaluationError: action.completedEvaluationError
			};

		case ActionTypes.GET_EVALUATION_SUMMARY_SUCCESS:
			return {
				...state,
				evaluationResultSummary: action.evaluationResultSummary,
				evaluationResultSummaryError: ''
			};

		case ActionTypes.GET_EVALUATION_SUMMARY_ERROR:
			return {
				...state,
				evaluationResultSummary: {},
				evaluationResultSummaryError: action.evaluationResultSummaryError
			};

		default:
			return state;
	}
};

export default evaluationsReducer;
