import React from 'react';
import { Switch, Route } from "react-router-dom";
import NavBar from "../Navi/NavBar";

import CompetitiveIntel from "../Pages/CompetitiveIntel";
import CompetitiveIntelFolderSub from '../Pages/CompetitiveIntelFolderSub';

const CompetitiveIntelRoute = () => {
    return (
        <React.Fragment>
            <NavBar />
            <Switch>
                <Route exact path="/competitiveintel" ><CompetitiveIntel /></Route>
                <Route exact path='/competitiveintel/folder/:id/:name'><CompetitiveIntelFolderSub /></Route>
            </Switch>
        </React.Fragment>
    )
}

export default CompetitiveIntelRoute;

