import React, { Component } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import classes from './PopoverEllipsis.module.css';

class PopoverButton extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			popoverOpen: false
		};
	}

	componentDidMount() {
		window.onclick = () => {
			if (this.state.popoverOpen) {
				this.toggle();
			}
		};
	}

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	render() {
		const { popoverOpen } = this.state;
		const { itemId, onEdit, onDelete } = this.props;
		return (
			<div>
				<div className={classes['three-circles']} id={`ci${itemId}`}>
					<div className={classes['three-circles__circle']} />
					<div className={classes['three-circles__circle']} />
					<div className={classes['three-circles__circle']} />
				</div>
				<Popover placement="left" isOpen={popoverOpen} target={`ci${itemId}`} toggle={this.toggle}>
					<p
						className={classes['popover_option']}
						style={styles.popover_action}
						onClick={() => {
							this.setState({ popoverOpen: false });
							onEdit();
						}}
					>
						EDIT
					</p>
					<hr style={{ margin: 0 }} />
					<p
						className={classes['popover_option']}
						style={styles.popover_action_red}
						onClick={() => {
							this.setState({ popoverOpen: false });
							onDelete();
						}}
					>
						DELETE
					</p>
				</Popover>
			</div>
		);
	}
}

const styles = {
	popover_action_red: {
		color: 'red'
	}
};

export default PopoverButton;
