import React, { useEffect, useState } from 'react';

import listClasses from '../List/List.module.css';
import classes from './SelectPersonList.module.css';

import ProfileImage from '../../Utils/ProfileImage';
import CreateRatingCircles from '../../Utils/CreateRatingCircles/CreateRatingCircles';

import useHandleSubmit from '../../Hooks/useHandleSubmit';

import { postEmailToRecipientAction } from '../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const SelectPersonList = ({ people, selectedPeople, personType, handleInputChange: customizeEmailHandleInputChange, inputs: customizeEmailInputs, callback }) => {
    const [ selectedItemID, setSelectedItemID ] = useState(null);
    const selectPerson = (ev, person) => {
        if (personType === 'customize-email-select-person')
        {
            let clonePeopleInRecipients = JSON.parse(JSON.stringify(customizeEmailInputs.recipients));
            let updatedPeopleInRecipients = clonePeopleInRecipients.filter(personInRecipients => personInRecipients.id !== person.id);
            if (updatedPeopleInRecipients.length === clonePeopleInRecipients.length)
            {
                updatedPeopleInRecipients.push(person);
            }

            customizeEmailHandleInputChange({
                target: {
                    name: 'recipients',
                    value: updatedPeopleInRecipients
                }
            });

            return;
        }

        if (personType === 'person')
        {
            return callback(ev, person);
        }

        if (personType === 'customer' &&
            person.id === selectedPeople.id)
        {
            return;
        }

        const searchForActiveClass = document.querySelector(`.${ classes[ 'list__item--active' ] }`);

        if (searchForActiveClass)
        {
            searchForActiveClass.classList.remove(classes[ 'list__item--active' ]);
        }

        setSelectedItemID(ev.currentTarget.id);
    };

    useEffect(() => {
        if (selectedItemID) {
            addOpacityToUnSelectedItems();
        }
    }, [ selectedItemID ]);

    useEffect(() => {
        if (selectedItemID)
        {
            addOpacityToUnSelectedItems();
        }
    }, [ people ])

    const addOpacityToUnSelectedItems = (ev) => {
        let listItems = document.querySelectorAll(`.${ listClasses[ 'list__item' ] }`);

        listItems.forEach((item) => {
            item.classList.add(classes[ 'list__item--opacity-low' ]);
        });

        let selectedItem = document.querySelector(`#${ selectedItemID }`)
        if (selectedItem)
        {
            selectedItem.classList.add(classes[ 'list__item--active' ]);
            selectedItem.classList.remove(classes[ 'list__item--opacity-low' ]);
        }
    }

    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();

    const [ formValidationErrors, setFormValidationErrors ] = useState({});
    const [ currentSubmittedInputName, setCurrentSubmittedInputName ] = useState('');

    const handleAddEmailFormSubmit = (ev, person) => {
        setCurrentSubmittedInputName(`email-${ person.id }`);

        if (inputs[ `email-${ person.id }` ] === "" || inputs[ `email-${ person.id }` ] === undefined)
        {
            return setFormValidationErrors(previousFormValidationErrors => ({
                ...previousFormValidationErrors,
                [ `email-${ person.id }` ]: 'This field is required.'
            }));
        }

        dispatch(postEmailToRecipientAction({
            trialID: id,
            personType: person.type,
            email: inputs[ `email-${ person.id }` ],
            personID: person.id,
            userTemplateID: engagementEmailID
        }));
    }

    const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
        callback: handleAddEmailFormSubmit,
        initialState: {}
    });


    const postEmailToRecipientError = useSelector(state => state.engagementEmails.postEmailToRecipientError);

    useEffect(() => {
        if (postEmailToRecipientError)
        {
            setFormValidationErrors(previousFormValidationErrors => ({
                ...previousFormValidationErrors,
                [ currentSubmittedInputName ]: postEmailToRecipientError.split('-')[ 0 ]
            }));
        }
    }, [ postEmailToRecipientError ]);

    const handleToggleInput = (event, person) => {
        let input = event.currentTarget.querySelector(`.${ classes[ "list__item-radio" ] }`);
        if (!input?.disabled) input.checked = !input.checked;
        selectPerson(event, person)

        if (personType === 'customer')
        {
            callback(person);
        }
    }

    return (
        people.map(item => {
            if (personType === 'customer')
            {
                return (
                    <React.Fragment key={ item.id }>
                        <label className="hidden" htmlFor={ `customer-${ item.id }` }>
                            { item.name } information
                        </label>

                        <div
                            onClick={ (event) => handleToggleInput(event, item) }
                            id={ `customer-${ item.id }` }
                            className={ [ listClasses[ 'list__item' ], "cursor" ].join(" ") } htmlFor={ `customer-${ item.id }` }>
                            <div className={ [ listClasses[ 'list__item-content' ], listClasses[ '-line-height' ] ].join(' ') }>
                                <div className={ listClasses[ 'list__item-name' ] }>{ item.name }</div>
                                <div className={ listClasses[ 'list__item-gray' ] }>customer: { item.address } </div>
                                <div className={ listClasses[ 'list__item-gray' ] }>trials: { item.total_trial } </div>
                            </div>
                            <input
                                className={ [ classes[ 'list__item-radio' ], selectedPeople.id === item.id ? classes[ 'list__item--active' ] : (''), 'hidden' ].join(' ') }
                                type="radio"
                                defaultChecked={ selectedPeople.id === item.id }
                                name="customer"
                                id={ `customer-${ item.id }` } />
                            <div className={ classes[ 'checkmark-circle' ] }>
                                <i className={ [ classes[ 'checkmark-circle__checkmark' ], 'las la-check' ].join(' ') } />
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else if (personType === 'customize-email-select-person')
            {
                return (
                    <React.Fragment key={ item.id }>
                        <label className="hidden" htmlFor={ `person-${ item.id }` }>{ item.name } information</label>

                        <div
                            onClick={ item.email !== "" ? ((event) => handleToggleInput(event, item)) : (undefined) }
                            className={ listClasses[ "list__item" ] }>

                            <div className={ [ listClasses[ "list__item-content" ], listClasses[ "-line-height" ], item.email === "" ? ("") : ("cursor") ].join(" ") }>
                                <div className={ listClasses[ "list__item-name" ] }>{ item.name }</div>
                                <div className="circles">
                                    <CreateRatingCircles amountOfCircles={ item.influence } />
                                </div>
                                <div className={ listClasses[ "list__item-gray" ] }>specialty / title: { item.title } </div>
                                { item.email === "" ? (
                                    <div className={ classes[ "add-email-container" ] }>
                                        <div className={ classes[ "add-email__form-elements" ] }>
                                            <label className="hidden" htmlFor={ `email-${ item.id }` }>add email</label>

                                            <input
                                                className={ classes[ "add-email__input" ] }
                                                onChange={ handleInputChange }
                                                value={ inputs[ `email-${ item.id }` ] || '' }
                                                name={ `email-${ item.id }` }
                                                id={ `email-${ item.id }` }
                                                placeholder="add email"
                                                type="email" />
                                            <button
                                                className={ classes[ "add-email__button" ] }
                                                onClick={ (ev) => handleSubmit(ev, item) }>
                                                submit
                                        </button>
                                        </div>
                                        { formValidationErrors[ `email-${ item.id }` ] ? (
                                            <div className={ classes[ "add-email__input-error" ] }>
                                                { formValidationErrors[ `email-${ item.id }` ] }
                                            </div>
                                        ) : (null) }
                                    </div>
                                ) : (
                                        <div className={ listClasses[ "list__item-gray" ] }>email: { item.email } </div>
                                    ) }

                            </div>
                            <input
                                disabled={ item.email === "" }
                                className={ [ classes[ "list__item-radio" ], "hidden" ].join(" ") }
                                type="checkbox"
                                defaultChecked={ item.is_selected === "1" }
                                name="person"
                                id={ `person-${ item.id }` } />
                            <div className={ [ classes[ "checkmark-circle" ], item.email === "" ? ("") : ("cursor") ].join(" ") }>
                                <i className={ [ classes[ "checkmark-circle__checkmark" ], "las la-check" ].join(" ") }></i>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment key={ item.id }>
                    <label className="hidden" htmlFor={ `person-${ item.id }` }>
                        { item.name } information
                    </label>
                    <div
                        onClick={ (event) => handleToggleInput(event, item) }
                        className={ [ listClasses[ "list__item" ], "cursor" ].join(" ") }>
                        <div
                            className={ listClasses[ "list__item-profile-image" ] }>
                            <ProfileImage profile={ item.profile } callback={ null } profileImageClasses="profile__image--small" />
                        </div>

                        <div className={ [ listClasses[ "list__item-content" ], listClasses[ "-line-height" ] ].join(" ") }>
                            <div className={ listClasses[ "list__item-name" ] }>{ item.name }</div>
                            <div className="circles">
                                <CreateRatingCircles amountOfCircles={ item.rating } />
                            </div>
                            <div className={ listClasses[ "list__item-gray" ] }>specialty / title: { item.title } </div>
                            <div className={ listClasses[ "list__item-gray" ] }>email: { item.email } </div>
                        </div>
                        <input
                            className={ [ classes[ "list__item-radio" ], "hidden" ].join(" ") }
                            defaultChecked={ selectedPeople[ item.id ] }
                            onClick={ (ev) => selectPerson(ev, item) }
                            type="checkbox"
                            name="person"
                            id={ `person-${ item.id }` } />
                        <div className={ classes[ "checkmark-circle" ] }>
                            <i className={ [ classes[ "checkmark-circle__checkmark" ], "las la-check" ].join(" ") }></i>
                        </div>
                    </div>
                </React.Fragment>
            );
        })
    );
}

export default SelectPersonList;