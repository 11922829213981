/** @format */

import React from "react";
import { Link, useParams } from "react-router-dom";

import ListSort from "./ListSort/ListSort";
import ComponentLoading from "../ComponentLoading/ComponentLoading";

import classes from "./List.module.css";

const List = (props) => {
  const {
    list,
    actions,
    sortAlphabeticallyByTitle,
    sortBy,
    view,
    removeSort,
    selectedCategory,
    isLoading,
  } = props;

  const { groupName, trialID, id, itemId } = useParams();
  if (list === false) {
    list = [];
  }

  let listFormatted;

  function createActionElements({ action, index, item }) {
    let actionElements;
    if (action.callback !== false) {
      actionElements = (
        <button
          key={index}
          className={classes["list__item-action"]}
          onClick={() => action.callback(item)}
        >
          {action.icon}
        </button>
      );
    } else if (action.link !== false) {
      actionElements = (
        <Link
          key={index}
          className={classes["list__item-action"]}
          to={action.link}
        >
          {action.icon}
        </Link>
      );
    } else {
      actionElements = (
        <div key={index} className={classes["list__item-action"]}>
          {action.icon}
        </div>
      );
    }

    return actionElements;
  }

  if (view === "draft-engagement-emails") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.user_template_id} className={classes["list__item"]}>
          <div
            className={[
              classes["list__item-content"],
              classes["-line-height"],
            ].join(" ")}
          >
            <div className={classes["list__item-name"]}>
              {item.user_template_name}
            </div>
            <div className={classes["list__item-recipients"]}>
              RECIPIENTS: {item.recipients} | LAST EDITED: {item.modified_date}{" "}
            </div>
          </div>

          {actions ? (
            <div className={classes["list__actions-container"]}>
              {actions.map((action, index) => {
                return createActionElements({ action, index, item });
              })}
            </div>
          ) : null}
        </li>
      );
    });
  } else if (view === "create-template-engagement-emails") {
    listFormatted = list.map((item) => {
      let emailBusinessUnit;

      if (item.bu_id === "1" || item.bu_id === "7") {
        emailBusinessUnit = "Communications";
      } else if (item.bu_id === "3" || item.bu_id === "9") {
        emailBusinessUnit = "Sports Med";
      } else {
        emailBusinessUnit = "Endoscopy";
      }

      return (
        <li key={item.id} className={classes["list__item"]}>
          <div
            className={[
              classes["list__item-content"],
              classes["-line-height"],
            ].join(" ")}
          >
            <div className={classes["list__item-name"]}>
              {item.name} ({emailBusinessUnit})
            </div>
            <div className={classes["list__item-recipients"]}>
              LAST SENT: {item.last_sent}{" "}
            </div>
          </div>

          {actions ? (
            <div className={classes["list__actions-container"]}>
              {actions.map((action, index) => {
                return createActionElements({ action, index, item });
              })}
            </div>
          ) : null}
        </li>
      );
    });
  } else if (view === "previous-engagement-emails") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.user_template_id} className={classes["list__item"]}>
          <div
            className={[
              classes["list__item-content"],
              classes["-line-height"],
            ].join(" ")}
          >
            <div className={classes["list__item-name"]}>
              {item.user_template_name}
            </div>
            <div className={classes["list__item-recipients"]}>
              LAST SENT: {item.last_sent}{" "}
            </div>
          </div>

          {actions ? (
            <div className={classes["list__actions-container"]}>
              {actions.map((action, index) => {
                return createActionElements({ action, index, item });
              })}
            </div>
          ) : null}
        </li>
      );
    });
  } else if (view === "sent-engagement-emails") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.user_template_id}>
          <Link
            className={classes["list__item"]}
            to={`engagement-emails/${item.user_template_id}/results`}
          >
            <div
              className={[
                classes["list__item-content"],
                classes["-line-height"],
              ].join(" ")}
            >
              <div className={classes["list__item-name"]}>
                {item.user_template_name}
              </div>
              <div className={classes["list__item-recipients"]}>
                {item.subject}
              </div>
              <div className={classes["list__item-recipients"]}>
                {item.recipients} recipients
              </div>
              <div className={classes["list__item-date"]}>
                Sent {item.send_date}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "talk-tracks") {
    let pageURL = window.location.href;
    let lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    listFormatted = list.map((item) => {
      let itemName = item.name.replace("/", "-").replace(/\s+/g, "-");
      if (item.noOfTracks > 0)
        return (
          <li key={item.id}>
            <Link
              className={classes["list__item"]}
              to={
                "/talktracks/" + lastURLSegment + "/" + itemName + "/" + item.id
              }
            >
              <div className={classes["list__item-content"]}>
                <div className={classes["list__item-name"]}>{item.name}</div>
                <div className={classes["list__item-recipients"]}>
                  {item.noOfTracks} TALK TRACK{item.noOfTracks == 1 ? "" : "S"}
                </div>
              </div>
              <i
                className={[
                  classes["link__item-icon"],
                  "las la-angle-right",
                ].join(" ")}
              />
            </Link>
          </li>
        );
    });
  } else if (view === "talk-tracks-sub-page") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.id}>
          <Link
            className={classes["list__item"]}
            to={
              "/talktracks/" +
              item.type +
              "/" +
              groupName +
              "/" +
              item.parentId +
              "/" +
              item.name.replace(/\s+/g, "-") +
              "/" +
              item.id
            }
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>{item.name}</div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "audio") {
    listFormatted = list.map((item) => {
      return (
        <li
          onClick={() => list.activateAudioPlayerModal(item)}
          className={[classes["list__item"], classes["list-audio-item"]].join(
            " "
          )}
          key={item.id}
        >
          <div className="audio-list__music-icon-container">
            <i className="las la-music" />
          </div>

          <div className={classes["list__item-content"]}>
            <div className={classes["list__item-name"]}>{item.name}</div>
            <div className={classes["list__item-gray"]}>{item.duration}</div>
            <div className={classes["list__item-date"]}>
              UPLOADED: {item.created_date}
            </div>
          </div>

          {item.isDownloaded ? (
            <div className="list__item-information">
              <img
                className="list__text-inline-image"
                src="/images/icon-cloud-download.png"
                alt="cloud icon"
              />
              <span>Available Offline</span>
            </div>
          ) : (
            <button
              onClick={(event) => list.storeAudioIntoIndexedDB(event, item)}
              className="audio-list__download-and-downloaded-button-icon list__item-information"
            >
              <img
                className="list__text-inline-image"
                src="/images/icon-cloud-download.png"
                alt="cloud icon"
              />
              <span id="saveForOffline">Save for offline</span>
            </button>
          )}
        </li>
      );
    });
  } else if (view === "trials") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.id}>
          <Link className={classes["list__item"]} to={"/trial/" + item.id}>
            <div className={classes["list__item-status"]}>
              {item.active === true ? (
                <img
                  className={classes["list__item-status-image"]}
                  src="/images/icon-active.jpg"
                  alt="active icon"
                />
              ) : (
                <img
                  className={classes["list__item-status-image"]}
                  src="/images/icon-inactive.jpg"
                  alt="in active icon"
                />
              )}
            </div>
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>
                {item.trialName || "-"}
              </div>
              <div className={classes["list__item-institution"]}>
                {item.name}
              </div>
              <div className={classes["list__item-date"]}>
                {item.createdDate}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "surveys") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.template_id}>
          <Link
            to={`/trial/${trialID}/surveyevals/survey/${item.template_id}`}
            key={item.id}
            value={item.template_name}
            className={[classes["list__item"], item.active].join(" ")}
            disabled={item.active === "inactive"}
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>
                {item.template_name}
              </div>
              <div className={classes["list__item-institution"]}>
                {item.questions} QUESTIONS | {item.respondents} RESPONDENTS
              </div>
              <div className={classes["list__item-date"]}>
                CREATED: {item.created_date}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "evaluations") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.template_id}>
          <Link
            to={`/trial/${trialID}/surveyevals/evaluation/${item.template_id}`}
            key={item.id}
            value={item.template_name}
            className={[classes["list__item"], item.active].join(" ")}
            disabled={item.active === "inactive"}
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>
                {item.template_name}
              </div>
              <div className={classes["list__item-institution"]}>
                {item.questions} QUESTIONS | {item.respondents} RESPONDENTS
              </div>
              <div className={classes["list__item-date"]}>
                CREATED: {item.created_date}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "template-surveys") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.template_id}>
          <Link
            to={`/trial/${trialID}/surveyevals/survey/templates/${item.template_id}`}
            key={item.id}
            value={item.template_name}
            className={[classes["list__item"], item.active].join(" ")}
            disabled={item.active === "inactive"}
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>
                {item.template_name}
              </div>
              <div className={classes["list__item-institution"]}>
                {item.questions} QUESTIONS | {item.respondents} RESPONDENTS
              </div>
              <div className={classes["list__item-date"]}>
                CREATED: {item.created_date}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "template-evaluations") {
    listFormatted = list.map((item) => {
      return (
        <li key={item.template_id}>
          <Link
            to={`/trial/${trialID}/surveyevals/evaluation/templates/${item.template_id}`}
            key={item.id}
            value={item.template_name}
            className={[classes["list__item"], item.active].join(" ")}
            disabled={item.active === "inactive"}
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>
                {item.template_name}
              </div>
              <div className={classes["list__item-institution"]}>
                {item.questions} QUESTIONS | {item.respondents} RESPONDENTS
              </div>
              <div className={classes["list__item-date"]}>
                CREATED: {item.created_date}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "related-trials") {
    listFormatted = list.map((item) => {
      let trialCount = Number.parseInt(item.related_trial);
      if (Number.isNaN(trialCount)) {
        trialCount = 0;
      }
      let idProperty = "";
      if (props.selectedCategory === "specialities")
        idProperty = "speciality_id";
      if (props.selectedCategory === "competitor") idProperty = "competitor_id";
      return (
        <li key={item[idProperty]}>
          <Link
            to={`/trial/${id}/intelligence/relatedTrials/${
              props.selectedCategory
            }/${encodeURIComponent(item.name)}/${item[idProperty]}`}
            key={item.id}
            value={item.template_name}
            className={[classes["list__item"], item.active].join(" ")}
            disabled={item.active === "inactive"}
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>{item.name}</div>
              <div className={classes["list__item-institution"]}>
                Related trial{trialCount !== 1 ? "s" : ""}: {trialCount}
              </div>
            </div>
            <i
              className={[
                classes["link__item-icon"],
                "las la-angle-right",
              ].join(" ")}
            />
          </Link>
        </li>
      );
    });
  } else if (view === "related-trial-item-detail") {
    listFormatted = list.map((item) => {
      let trialCount = Number.parseInt(item.related_trial);
      if (Number.isNaN(trialCount)) {
        trialCount = 0;
      }
      let idProperty = "";
      let inTrialDetails = "";
      if (selectedCategory === "specialities") {
        idProperty = "speciality_id";
        inTrialDetails = `Specialties in this trial: ${item.specialities_in_trial}`;
      } else {
        idProperty = "competitor_id";
        inTrialDetails = `Competitors in this trial: ${item.competitors_in_trial}`;
      }
      return (
        <li key={item.trial_id}>
          <div
            key={item.id}
            className={[classes["list__item"], item.active].join(" ")}
            disabled={item.active === "inactive"}
          >
            <div className={classes["list__item-content"]}>
              <div className={classes["list__item-name"]}>
                {item.name.trim().length > 0 ? item.name : "-"}
              </div>
              <div className={classes["list__item-description"]}>
                Account: {item.trial_name}
              </div>
              <div className={classes["list__item-description"]}>
                Start Date: {item.start_date}
              </div>
              <div className={classes["list__item-description"]}>
                End Date: {item.end_date}
              </div>
              <div className={classes["list__item-description"]}>
                {inTrialDetails}
              </div>
            </div>
            <a
              className={classes["link__action-item"]}
              href={`mailto:${item.email}`}
            >
              <i
                className={[
                  classes["link__item-icon-white"],
                  "las la-envelope",
                ].join(" ")}
              />
            </a>
          </div>
        </li>
      );
    });
  }

  return (
    <div>
      {list.length ? (
        removeSort ? null : (
          <ListSort
            sortBy={sortBy}
            sortAlphabeticallyByTitle={sortAlphabeticallyByTitle}
          />
        )
      ) : null}

      <ul className={classes["list"]}>{listFormatted}</ul>

      {isLoading ? (
        <ComponentLoading message="Loading..." removeHeight={true} />
      ) : null}
    </div>
  );
};

export default List;
