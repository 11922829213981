export const GET_USER_COUNTERS_SUCCESS = 'GET_USER_COUNTERS_SUCCESS';
export const GET_USER_COUNTERS_ERROR = 'GET_USER_COUNTERS_ERROR';

export const REQUEST_SPECIALTY = 'REQUEST_SPECIALTY';
export const GET_SPECIALTY_SUCCESS = 'GET_SPECIALTY_SUCCESS';
export const GET_SPECIALTY_ERROR = 'GET_SPECIALTY_ERROR';

export const REQUEST_PRODUCT_EDUCATION = 'REQUEST_PRODUCT_EDUCATION';
export const GET_PRODUCT_EDUCATION_SUCCESS = 'GET_PRODUCT_EDUCATION_SUCCESS';
export const GET_PRODUCT_EDUCATION_ERROR = 'GET_PRODUCT_EDUCATION_ERROR';

export const GET_PRODUCT_EDUCATION_SINGLE_SUCCESS = 'GET_PRODUCT_EDUCATION_SINGLE_SUCCESS';
export const GET_PRODUCT_EDUCATION_SINGLE_ERROR = 'GET_PRODUCT_EDUCATION_SINGLE_ERROR';

export const GET_PRODUCT_EDUCATION_SEARCH_RESULTS_SUCCESS = 'GET_PRODUCT_EDUCATION_SEARCH_RESULTS_SUCCESS';
export const GET_PRODUCT_EDUCATION_SEARCH_RESULTS_ERROR = 'GET_PRODUCT_EDUCATION_SEARCH_RESULTS_ERROR';

export const REQUEST_CONTENT_LIST = 'REQUEST_CONTENT_LIST';
export const GET_CONTENT_LIST_SUCCESS = 'GET_CONTENT_LIST_SUCCESS';
export const GET_CONTENT_LIST_ERROR = 'GET_CONTENT_LIST_ERROR';

export const REQUEST_COMPETITIVE_INTEL_SEARCH_RESULTS = 'REQUEST_COMPETITIVE_INTEL_SEARCH_RESULTS';
export const GET_COMPETITIVE_INTEL_SEARCH_RESULTS_SUCCESS = 'GET_COMPETITIVE_INTEL_SEARCH_RESULTS_SUCCESS';
export const GET_COMPETITIVE_INTEL_SEARCH_RESULTS_ERROR = 'GET_COMPETITIVE_INTEL_SEARCH_RESULTS_ERROR';

export const REQUEST_CONTENT_FOLDER_LIST = 'REQUEST_CONTENT_FOLDER_LIST';
export const GET_CONTENT_FOLDER_LIST_SUCCESS = 'GET_CONTENT_FOLDER_LIST_SUCCESS';
export const GET_CONTENT_FOLDER_LIST_ERROR = 'GET_CONTENT_FOLDER_LIST_ERROR';

export const REQUEST_TRIALS = 'REQUEST_TRIALS';
export const GET_TRIALS_SUCCESS = 'GET_TRIALS_SUCCESS';
export const GET_TRIALS_ERROR = 'GET_TRIALS_ERROR';

export const REQUEST_TRIAL_STATISTICS = 'REQUEST_TRIAL_STATISTICS';
export const GET_TRIAL_STATISTICS_SUCCESS = 'GET_TRIALS_STATISTICS_SUCCESS';
export const GET_TRIAL_STATISTICS_ERROR = 'GET_TRIAL_STATISTICS_ERROR';

export const DELETE_TRIAL_SUCCESS = 'DELETE_TRIAL_SUCCESS';
export const DELETE_TRIAL_ERROR = 'DELETE_TRIAL_ERROR';

export const GET_TRIAL_MAIN_SUCCESS = 'GET_TRIAL_MAIN_SUCCESS';
export const GET_TRIAL_MAIN_ERROR = 'GET_TRIAL_MAIN_ERROR';

export const REQUEST_TRIAL_DETAILS = 'REQUEST_TRIAL_DETAILS';
export const GET_TRIAL_DETAIL_SUCCESS = 'GET_TRIAL_DETAIL_SUCCESS';
export const GET_TRIAL_DETAIL_ERROR = 'GET_TRIAL_DETAIL_ERROR';

export const REQUEST_TRIAL_PEOPLE = 'REQUEST_TRIAL_PEOPLE';
export const GET_TRIAL_PEOPLE_SUCCESS = 'GET_TRIAL_PEOPLE_SUCCESS';
export const GET_TRIAL_PEOPLE_ERROR = 'GET_TRIAL_PEOPLE_ERROR';

export const GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_SUCCESS = 'GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_SUCCESS';
export const GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_ERROR = 'GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_ERROR';

export const PUT_TRIAL_STATUS_SUCCESS = 'PUT_TRIAL_STATUS_SUCCESS';
export const PUT_TRIAL_STATUS_ERROR = 'PUT_TRIAL_STATUS_ERROR';

export const PUT_TRIAL_INFORMATION_SUCCESS = 'PUT_TRIAL_INFORMATION_SUCCESS';
export const PUT_TRIAL_INFORMATION_ERROR = 'PUT_TRIAL_INFORMATION_ERROR';

export const REQUEST_TALK_TRACK_SINGLE = 'REQUEST_TALK_TRACK_SINGLE';
export const GET_GROUP_TRACK_SINGLE_SUCCESS = 'GET_GROUP_TRACK_SINGLE_SUCCESS';
export const GET_GROUP_TRACK_SINGLE_ERROR = 'GET_GROUP_TRACK_SINGLE_ERROR';

export const GET_GROUP_TRACK_AUDIO_SUCCESS = 'GET_GROUP_TRACK_AUDIO_SUCCESS';
export const GET_GROUP_TRACK_AUDIO_ERROR = 'GET_GROUP_TRACK_AUDIO_ERROR';

export const REQUEST_STAKEHOLDERS = 'REQUEST_STAKEHOLDERS';
export const GET_STAKEHOLDERS_SUCCESS = 'GET_STAKEHOLDERS_SUCCESS';
export const GET_STAKEHOLDERS_ERROR = 'GET_STAKEHOLDERS_ERROR';

export const GET_BU_SUCCESS = 'GET_BU_SUCCESS';
export const GET_BU_ERROR = 'GET_BU_ERROR';

export const POST_BU_ERROR = 'POST_BU_ERROR';

export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';

export const POST_USER_INFORMATION_SUCCESS = 'POST_USER_INFORMATION_SUCCESS';
export const POST_USER_INFORMATION_ERROR = 'POST_USER_INFORMATION_ERROR';

export const POST_TEAMMATE_SUCCESS = 'POST_TEAMMATE_SUCCESS';
export const POST_TEAMMATE_ERROR = 'POST_TEAMMATE_ERROR';

export const DELETE_TEAMMATE_ERROR = 'DELETE_TEAMMATE_ERROR';
export const DELETE_TEAMMATE_SUCCESS = 'DELETE_TEAMMATE_SUCCESS';

export const GET_TEAMMATES_FOR_SEARCH_SUCCESS = 'GET_TEAMMATES_FOR_SEARCH_SUCCESS';
export const GET_TEAMMATES_FOR_SEARCH_ERROR = 'GET_TEAMMATES_FOR_SEARCH_ERROR';

export const GET_TEAMMATES_SUCCESS = 'GET_TEAMMATES_SUCCESS';
export const GET_TEAMMATES_ERROR = 'GET_TEAMMATES_ERROR';

export const REQUEST_GET_CASE = 'REQUEST_GET_CASE';
export const GET_CASE_SUCCESS = 'GET_CASE_SUCCESS';
export const GET_CASE_ERROR = 'GET_CASE_ERROR';

export const POST_CASE_SUCCESS = 'POST_CASE_SUCCESS';
export const POST_CASE_ERROR = 'POST_CASE_ERROR';

export const PUT_CASE_SUCCESS = 'PUT_CASE_SUCCESS';
export const PUT_CASE_ERROR = 'PUT_CASE_ERROR';

export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS';
export const DELETE_CASE_ERROR = 'DELETE_CASE_ERROR';

export const REQUEST_CASE_SURGEONS = 'REQUEST_CASE_SURGEONS'
export const GET_CASE_SURGEONS_SUCCESS = 'GET_CASE_SURGEONS_SUCCESS';
export const GET_CASE_SURGEONS_ERROR = 'GET_CASE_SURGEONS_ERROR';

export const REQUEST_GET_SURGEON = 'REQUEST_GET_SURGEON';
export const GET_SURGEON_SUCCESS = 'GET_SURGEON_SUCCESS';
export const GET_SURGEON_ERROR = 'GET_SURGEON_ERROR';

export const GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_SUCCESS = 'GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_SUCCESS';
export const GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_ERROR = 'GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_ERROR';

export const POST_SURGEON_TO_TRIAL_SUCCESS = 'POST_SURGEON_TO_TRIAL_SUCCESS';
export const POST_SURGEON_TO_TRIAL_ERROR = 'POST_SURGEON_TO_TRIAL_ERROR';

export const DELETE_SURGEON_FROM_TRIAL_SUCCESS = 'DELETE_SURGEON_FROM_TRIAL_SUCCESS';
export const DELETE_SURGEON_FROM_TRIAL_ERROR = 'DELETE_SURGEON_FROM_TRIAL_ERROR';

export const REQUEST_RELATED_TRIALS = 'REQUEST_RELATED_TRIALS';
export const GET_RELATED_TRIAL_SUCCESS = 'GET_RELATED_TRIAL_SUCCESS';
export const GET_RELATED_TRIAL_ERROR = 'GET_CRELATED_TRIAL_ERROR';

export const REQUEST_RELATED_TRIAL_ITEM_DETAILS = 'REQUEST_RELATED_TRIAL_ITEM_DETAILS';
export const GET_RELATED_TRIAL_DETAIL_SUCCESS = 'GET_RELATED_TRIAL_DETAIL_SUCCESS';
export const GET_RELATED_TRIAL_DETAIL_ERROR = 'GET_RELATED_TRIAL_DETAIL_ERROR';

export const REQUEST_GET_CHECKLISTS = 'REQUEST_GET_CHECKLISTS';
export const GET_CHECKLISTS_SUCCESS = 'GET_CHECKLIST_SUCCESS';
export const GET_CHECKLISTS_ERROR = 'GET_CHECKLIST_ERROR';

export const POST_CHECKLIST_SUCCESS = 'POST_CHECKLIST_SUCCESS';
export const POST_CHECKLIST_ERROR = 'POST_CHECKLIST_ERROR';

export const PUT_CHECKLIST_SUCCESS = 'PUT_CHECKLIST_SUCCESS';
export const PUT_CHECKLIST_ERROR = 'PUT_CHECKLIST_ERROR';

export const DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS';
export const DELETE_CHECKLIST_ERROR = 'DELETE_CHECKLIST_ERROR';

export const POST_CHECKLIST_ITEM_SUCCESS = 'POST_CHECKLIST_ITEM_SUCCESS';
export const POST_CHECKLIST_ITEM_ERROR = 'POST_CHECKLIST_ITEM_ERROR';

export const PUT_CHECKLIST_ITEM_SUCCESS = 'PUT_CHECKLIST_ITEM_SUCCESS';
export const PUT_CHECKLIST_ITEM_ERROR = 'PUT_CHECKLIST_ITEM_ERROR';

export const DELETE_CHECKLIST_ITEM_SUCCESS = 'DELETE_CHECKLIST_ITEM_SUCCESS';
export const DELETE_CHECKLIST_ITEM_ERROR = 'DELETE_CHECKLIST_ITEM_ERROR';

export const PUT_CHECKLIST_ITEM_STATUS_SUCCESS = 'PUT_CHECKLIST_ITEM_STATUS_SUCCESS';
export const PUT_CHECKLIST_ITEM_STATUS_ERROR = 'PUT_CHECKLIST_ITEM_STATUS_ERROR';

export const GET_CHECKLIST_COMMENTS_SUCCESS = 'GET_CHECKLIST_COMMENTS_SUCCESS';
export const GET_CHECKLIST_COMMENTS_ERROR = 'GET_CHECKLIST_COMMENTS_ERROR';

export const REQUEST_GET_TRIALS_AVAILABLE_CUSTOMERS = 'REQUEST_GET_TRIALS_AVAILABLE_CUSTOMERS';
export const GET_TRIALS_AVAILABLE_CUSTOMERS_SUCCESS = 'GET_TRIALS_AVAILABLE_CUSTOMERS_SUCCESS';
export const GET_TRIALS_AVAILABLE_CUSTOMERS_ERROR = 'GET_TRIALS_AVAILABLE_CUSTOMERS_ERROR';

export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS';
export const GET_CUSTOMER_DETAILS_ERROR = 'GET_CUSTOMER_DETAILS_ERROR';

export const GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';
export const GET_PEOPLE_ERROR = 'GET_PEOPLE_ERROR';

export const POST_CHECKLIST_COMMENT_SUCCESS = 'POST_CHECKLIST_COMMENT_SUCCESS';
export const POST_CHECKLIST_COMMENT_ERROR = 'POST_CHECKLIST_COMMENT_ERROR';

export const POST_CHECKLIST_ASSIGN_TEAMMATE_SUCCESS = 'POST_CHECKLIST_ASSIGN_TEAMMATE_SUCCESS';
export const POST_CHECKLIST_ASSIGN_TEAMMATE_ERROR = 'POST_CHECKLIST_ASSIGN_TEAMMATE_ERROR';

export const POST_CHECKLIST_REMOVE_TEAMMATE_SUCCESS = 'POST_CHECKLIST_REMOVE_TEAMMATE_SUCCESS';
export const POST_CHECKLIST_REMOVE_TEAMMATE_ERROR = 'POST_CHECKLIST_REMOVE_TEAMMATE_ERROR';

export const POST_CHECKLIST_EDIT_COMMENT_SUCCESS = 'POST_CHECKLIST_EDIT_COMMENT_SUCCESS';
export const POST_CHECKLIST_EDIT_COMMENT_ERROR = 'POST_CHECKLIST_EDIT_COMMENT_ERROR';

export const POST_CHECKLIST_DELETE_COMMENT_SUCCESS = 'POST_CHECKLIST_DELETE_COMMENT_SUCCESS';
export const POST_CHECKLIST_DELETE_COMMENT_ERROR = 'POST_CHECKLIST_DELETE_COMMENT_ERROR';

export const GET_SURVEYS_AND_EVALUATIONS_SUCCESS = 'GET_SURVEYS_AND_EVALUATIONS_SUCCESS';
export const GET_SURVEYS_AND_EVALUATIONS_ERROR = 'GET_SURVEYS_AND_EVALUATIONS_ERROR';

export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';

export const GET_EVALUATION_TEMPLATE_SUCCESS = 'GET_EVALUATION_TEMPLATE_SUCCESS';
export const GET_EVALUATION_TEMPLATE_ERROR = 'GET_EVALUATION_TEMPLATE_ERROR';

export const GET_SURVEY_TEMPLATE_SUCCESS = 'GET_SURVEY_TEMPLATE_SUCCESS';
export const GET_SURVEY_TEMPLATE_ERROR = 'GET_SURVEY_TEMPLATE_ERROR';

export const CREATE_SURVEYS_AND_EVALUATIONS_TEMPLATE_NAME_SUCCESS =
	'CREATE_SURVEYS_AND_EVALUATIONS_TEMPLATE_NAME_SUCCESS';
export const CREATE_SURVEYS_AND_EVALUATIONS_TEMPLATE_NAME_ERROR = 'CREATE_SURVEYS_AND_EVALUATIONS_TEMPLATE_NAME_ERROR';

export const CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_SUCCESS = 'CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_SUCCESS';
export const CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_ERROR = 'CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_ERROR';

export const GET_SURVEYS_AND_EVALUATIONS_RESPONDENTS_SUCCESS = 'GET_SURVEYS_AND_EVALUATIONS_RESPONDENTS_SUCCESS';
export const GET_SURVEYS_AND_EVALUATIONS_RESPONDENTS_ERROR = 'GET_SURVEYS_AND_EVALUATIONS_RESPONDENTS_ERROR';

export const DUPLICATE_SURVEYS_AND_EVALUATIONS_SUCCESS = 'DUPLICATE_SURVEYS_AND_EVALUATIONS_SUCCESS';
export const DUPLICATE_SURVEYS_AND_EVALUATIONS_ERROR = 'DUPLICATE_SURVEYS_AND_EVALUATIONS_ERROR';

export const REQUEST_GET_SURVEYS = 'REQUEST_GET_SURVEYS';
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS';
export const GET_SURVEYS_ERROR = 'GET_SURVEYS_ERROR';

export const REQUEST_GET_SURVEY_TEMPLATES = 'REQUEST_GET_SURVEY_TEMPLATES';
export const GET_SURVEY_TEMPLATES_SUCCESS = 'GET_SURVEY_TEMPLATES_SUCCESS';
export const GET_SURVEY_TEMPLATES_ERROR = 'GET_SURVEY_TEMPLATES_ERROR';

export const REQUEST_GET_SURVEY_CRUSH_TEMPLATES = 'REQUEST_GET_SURVEY_CRUSH_TEMPLATES';
export const GET_SURVEY_CRUSH_TEMPLATES_SUCCESS = 'GET_SURVEY_CRUSH_TEMPLATES_SUCCESS';
export const GET_SURVEY_CRUSH_TEMPLATES_ERROR = 'GET_SURVEY_CRUSH_TEMPLATES_ERROR';

export const CREATE_SURVEYS_TEMPLATE_NAME_SUCCESS = 'CREATE_SURVEYS_TEMPLATE_NAME_SUCCESS';
export const CREATE_SURVEYS_TEMPLATE_NAME_ERROR = 'CREATE_SURVEYS_TEMPLATE_NAME_ERROR';

export const CREATE_SURVEYS_QUESTION_SUCCESS = 'CREATE_SURVEYS_QUESTION_SUCCESS';
export const CREATE_SURVEYS_QUESTION_ERROR = 'CREATE_SURVEYS_QUESTION_ERROR';

export const GET_SURVEYS_RESPONDENTS_SUCCESS = 'GET_SURVEYS_RESPONDENTS_SUCCESS';
export const GET_SURVEYS_RESPONDENTS_ERROR = 'GET_SURVEYS_RESPONDENTS_ERROR';

export const GET_SURVEY_SUMMARY_SUCCESS = 'GET_EVALUATION_SUMMARY_SUCCESS';
export const GET_SURVEY_SUMMARY_ERROR = 'GET_SURVEY_SUMMARY_ERROR';

export const DUPLICATE_SURVEYS_SUCCESS = 'DUPLICATE_SURVEYS_SUCCESS';
export const DUPLICATE_SURVEYS_ERROR = 'DUPLICATE_SURVEYS_ERROR';

export const REQUEST_GET_EVALUATIONS = 'REQUEST_GET_EVALUATIONS';
export const GET_EVALUATIONS_SUCCESS = 'GET_EVALUATIONS_SUCCESS';
export const GET_EVALUATIONS_ERROR = 'GET_EVALUATIONS_ERROR';

export const GET_EVALUATION_RESULTS_SUCCESS = 'GET_EVALUATION_RESULTS_SUCCESS';
export const GET_EVALUATION_RESULTS_ERROR = 'GET_EVALUATION_RESULTS_ERROR';

export const GET_SURVEY_RESULTS_SUCCESS = 'GET_SURVEY_RESULTS_SUCCESS';
export const GET_SURVEY_RESULTS_ERROR = 'GET_SURVEY_RESULTS_ERROR';

export const REQUEST_GET_EVALUATION_TEMPLATES = 'GET_EVALUATION_TEMPLATES';
export const GET_EVALUATION_TEMPLATES_SUCCESS = 'GET_EVALUATION_TEMPLATES_SUCCESS';
export const GET_EVALUATION_TEMPLATES_ERROR = 'GET_EVALUATION_TEMPLATES_ERROR';

export const REQUEST_GET_EVALUATION_CRUSH_TEMPLATES = 'GET_EVALUATION_CRUSH_TEMPLATES';
export const GET_EVALUATION_CRUSH_TEMPLATES_SUCCESS = 'GET_EVALUATION_CRUSH_TEMPLATES_SUCCESS';
export const GET_EVALUATION_CRUSH_TEMPLATES_ERROR = 'GET_EVALUATION_CRUSH_TEMPLATES_ERROR';

export const CREATE_EVALUATIONS_TEMPLATE_NAME_SUCCESS = 'CREATE_EVALUATIONS_TEMPLATE_NAME_SUCCESS';
export const CREATE_EVALUATIONS_TEMPLATE_NAME_ERROR = 'CREATE_EVALUATIONS_TEMPLATE_NAME_ERROR';

export const CREATE_EVALUATIONS_QUESTION_SUCCESS = 'CREATE_EVALUATIONS_QUESTION_SUCCESS';
export const CREATE_EVALUATIONS_QUESTION_ERROR = 'CREATE_EVALUATIONS_QUESTION_ERROR';

export const GET_EVALUATIONS_RESPONDENTS_SUCCESS = 'GET_EVALUATIONS_RESPONDENTS_SUCCESS';
export const GET_EVALUATIONS_RESPONDENTS_ERROR = 'GET_EVALUATIONS_RESPONDENTS_ERROR';

export const DUPLICATE_EVALUATIONS_SUCCESS = 'DUPLICATE_EVALUATIONS_SUCCESS';
export const DUPLICATE_EVALUATIONS_ERROR = 'DUPLICATE_EVALUATIONS_ERROR';

export const GET_EVALUATION_SUMMARY_SUCCESS = 'GET_EVALUATION_SUMMARY_SUCCESS';
export const GET_EVALUATION_SUMMARY_ERROR = 'GET_EVALUATION_SUMMARY_ERROR';

export const REQUEST_DRAFT_ENGAGEMENT_EMAILS = 'REQUEST_DRAFT_ENGAGEMENT_EMAILS';
export const GET_DRAFT_ENGAGEMENT_EMAILS_SUCCESS = 'GET_DRAFT_ENGAGEMENT_EMAILS_SUCCESS';
export const GET_DRAFT_ENGAGEMENT_EMAILS_ERROR = 'GET_DRAFT_ENGAGEMENT_EMAILS_ERROR';

export const REQUEST_PREVIOUS_ENGAGEMENT_EMAILS = 'REQUEST_PREVIOUS_ENGAGEMENT_EMAILS';
export const GET_PREVIOUS_ENGAGEMENT_EMAILS_SUCCESS = 'GET_PREVIOUS_ENGAGEMENT_EMAILS_SUCCESS';
export const GET_PREVIOUS_ENGAGEMENT_EMAILS_ERROR = 'GET_PREVIOUS_ENGAGEMENT_EMAILS_ERROR';

export const REQUEST_TEMPLATE_ENGAGEMENT_EMAILS = 'REQUEST_TEMPLATE_ENGAGEMENT_EMAILS';
export const GET_TEMPLATE_ENGAGEMENT_EMAILS_SUCCESS = 'GET_TEMPLATE_ENGAGEMENT_EMAILS_SUCCESS';
export const GET_TEMPLATE_ENGAGEMENT_EMAILS_ERROR = 'GET_TEMPLATE_ENGAGEMENT_EMAILS_ERROR';

export const GET_ENGAGEMENT_EMAIL_RESULTS_SUCCESS = 'GET_ENGAGEMENT_EMAIL_RESULTS_SUCCESS';
export const GET_ENGAGEMENT_EMAIL_RESULTS_ERROR = 'GET_ENGAGEMENT_EMAIL_RESULTS_ERROR';

export const REQUEST_ENGAGEMENT_EMAIL_VERBATIM = 'REQUEST_ENGAGEMENT_EMAIL_VERBATIM';
export const GET_ENGAGEMENT_EMAIL_VERBATIM_SUCCESS = 'GET_ENGAGEMENT_EMAIL_VERBATIM_SUCCESS';
export const GET_ENGAGEMENT_EMAIL_VERBATIM_ERROR = 'GET_ENGAGEMENT_EMAIL_VERBATIM_ERROR';

export const GET_STAKEHOLDER_TITLES_SUCCESS = 'GET_STAKEHOLDER_TITLES_SUCCESS';
export const GET_STAKEHOLDER_TITLES_ERROR = 'GET_STAKEHOLDER_TITLES_ERROR';

export const REQUEST_SPECIALITIES = 'REQUEST_SPECIALITIES'
export const GET_SPECIALITIES_SUCCESS = 'GET_SPECIALITIES_SUCCESS';
export const GET_SPECIALITIES_ERROR = 'GET_SPECIALITIES_ERROR';

export const POST_SPECIALITIES_SUCCESS = 'POST_SPECIALITIES_SUCCESS';
export const POST_SPECIALITIES_ERROR = 'POST_SPECIALITIES_ERROR';

export const DELETE_SPECIALITIES_SUCCESS = 'DELETE_SPECIALITIES_SUCCESS';
export const DELETE_SPECIALITIES_ERROR = 'DELETE_SPECIALITIES_ERROR';

export const REQUEST_COMPETITORS = 'REQUEST_COMPETITORS'
export const GET_COMPETITORS_SUCCESS = 'GET_COMPETITORS_SUCCESS';
export const GET_COMPETITORS_ERROR = 'GET_COMPETITORS_ERROR';

export const POST_COMPETITORS_SUCCESS = 'POST_COMPETITORS_SUCCESS';
export const POST_COMPETITORS_ERROR = 'POST_COMPETITORS_ERROR';

export const DELETE_COMPETITORS_SUCCESS = 'DELETE_COMPETITORS_SUCCESS';
export const DELETE_COMPETITORS_ERROR = 'DELETE_COMPETITORS_ERROR';

export const POST_SURGEONS_SUCCESS = 'POST_SURGEONS_SUCCESS';
export const POST_SURGEONS_ERROR = 'POST_SURGEONS_ERROR';

export const DELETE_SURGEON_SUCCESS = 'DELETE_SURGEON_SUCCESS';
export const DELETE_SURGEON_ERROR = 'DELETE_SURGEON_ERROR';

export const POST_STAKEHOLDER_SUCCESS = 'POST_STAKEHOLDER_SUCCESS';
export const POST_STAKEHOLDER_ERROR = 'POST_STAKEHOLDER_ERROR';

export const DELETE_STAKEHOLDER_SUCCESS = 'DELETE_STAKEHOLDER_SUCCESS';
export const DELETE_STAKEHOLDER_ERROR = 'DELETE_STAKEHOLDER_ERROR';

export const RESET_RECIPIENTS_IN_REDUCER = 'RESET_RECIPIENTS_IN_REDUCER';
export const REQUEST_RECIPIENTS = 'REQUEST_RECIPIENTS';
export const GET_RECIPIENTS_SUCCESS = 'GET_RECIPIENTS_SUCCESS';
export const GET_RECIPIENTS_ERROR = 'GET_RECIPIENTS_ERROR';

export const RESET_TRIALS_REDUCER = 'RESET_TRIALS_REDUCER';

export const RESET_COMPETIIVE_INTEL_FOLDER_IN_REDUCER = 'RESET_COMPETITIVE_INTEL_REDUCER';

export const RESET_TALK_TRACK_SINGLE_IN_REDUCER = 'RESET_TALK_TRACK_SINGLE_IN_REDUCER';

export const GET_ENGAGEMENT_EMAIL_SUCCESS = 'GET_ENGAGEMENT_EMAIL_SUCCESS';
export const GET_ENGAGEMENT_EMAIL_ERROR = 'GET_ENGAGEMENT_EMAIL_ERROR';

export const GET_ENGAGEMENT_EMAIL_IMAGES_SUCCESS = 'GET_ENGAGEMENT_EMAIL_IMAGES_SUCCESS';
export const GET_ENGAGEMENT_EMAIL_IMAGES_ERROR = 'GET_ENGAGEMENT_EMAIL_IMAGES_ERROR';

export const DELETE_ENGAGEMENT_EMAIL_DRAFT_SUCCESS = 'DELETE_ENGAGEMENT_EMAIL_DRAFT_SUCCESS';
export const DELETE_ENGAGEMENT_EMAIL_DRAFT_ERROR = 'DELETE_ENGAGEMENT_EMAIL_DRAFT_ERROR';

export const SEND_TEST_ENGAGEMENT_EMAIL_SUCCESS = 'SEND_TEST_ENGAGEMENT_EMAIL_SUCCESS';
export const SEND_TEST_ENGAGEMENT_EMAIL_ERROR = 'SEND_TEST_ENGAGEMENT_EMAIL_ERROR';

export const POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_SUCCESS = 'POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_SUCCESS';
export const POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_ERROR = 'POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_ERROR';

export const CLONE_PREVIOUS_EMAIL_SUCCESS = 'CLONE_PREVIOUS_EMAIL_SUCCESS';
export const CLONE_PREVIOUS_EMAIL_ERROR = 'CLONE_PREVIOUS_EMAIL_ERROR';

export const GET_COMPLETED_EVALUATION_SUCCESS = 'GET_COMPLETED_EVALUATION_SUCCESS';
export const GET_COMPLETED_EVALUATION_ERROR = 'GET_COMPLETED_EVALUATION_ERROR';

export const POST_EMAIL_TO_RECIPIENT_SUCCESS = 'POST_EMAIL_TO_RECIPIENT_SUCCESS';
export const POST_EMAIL_TO_RECIPIENT_ERROR = 'POST_EMAIL_TO_RECIPIENT_ERROR';

export const POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_SUCCESS = 'POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_SUCCESS';
export const POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_ERROR = 'POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_ERROR';

export const POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_SUCCESS = 'POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_SUCCESS';
export const POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_ERROR = 'POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_ERROR';

export const POST_SEND_MAIL_SUCCESS = 'POST_SEND_MAIL_SUCCESS';
export const POST_SEND_MAIL_ERROR = 'POST_SEND_MAIL_ERROR';

export const GET_COMPLETED_SURVEY_SUCCESS = 'GET_COMPLETED_SURVEY_SUCCESS';
export const GET_COMPLETED_SURVEY_ERROR = 'GET_COMPLETED_SURVEY_ERROR';

export const GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_SUCCESS = 'GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_SUCCESS';
export const GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_ERROR = 'GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_ERROR';

export const GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_SUCCESS = 'GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_SUCCESS';
export const GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_ERROR = 'GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_ERROR';

export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';

export const RESET_CREATED_PERSON_ID_IN_TRIALS_REDUCER = 'RESET_CREATED_PERSON_ID_IN_TRIALS_REDUCER';

export const RESET_PRODUCT_EDUCATION_SINGLE_IN_REDUCER = 'RESET_PRODUCT_EDUCATION_SINGLE_IN_REDUCER';

export const REQUEST_GET_PEOPLE = 'REQUEST_GET_PEOPLE';