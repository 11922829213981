import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useHandleSubmit from '../../../../Hooks/useHandleSubmit';
import { useDispatch } from 'react-redux';
import { postSurgeonAction, postStakeholderAction } from '../../../../Store/actions/trialsAction';

import classes from '../InputElements.module.css';

import FirstView from './views/FirstView';
import SecondView from './views/SecondView';
import ThirdView from './views/ThirdView';

const AddPersonalDetails = ({ toggleModal, accountID, refreshPeople, noTrialID = false, addSurgeon = false }) => {
	const { id } = useParams();
	const dispatch = useDispatch();

	const handleCustomFormSubmit = (event) => {
		if (event) {
			event.stopPropagation();
		}

		if (currentView === 2 || (currentView === 1 && inputs.type === 'stakeholder' && addSurgeon === false)) {
			let formData = new FormData();
			formData.append('first_name', inputs.firstName);
			formData.append('last_name', inputs.lastName);
			formData.append('email', inputs.primaryEmail);
			formData.append('secondary_email', inputs.secondaryEmail);
			formData.append('mobile_phone', inputs.mobilePhone);
			formData.append('work_phone', inputs.workPhone);
			formData.append('rating', inputs.rating);
			formData.append('facility', '');
			formData.append('customer_id', accountID);
			formData.append('trial_id', id || '');
			formData.append('user_id', localStorage.getItem('user_id'));
			formData.append('profile', inputs.profilePicture);
			if (inputs.personTitleAndID !== '') {
				let [ personID, title ] = inputs.personTitleAndID.split('-');

				if (title === 'Add new title') {
					formData.append('title', inputs.stakeholderTitleText);
				} else {
					formData.append('title', title);
				}

				if (inputs.type === 'surgeon') {
					formData.append('specialty_id', personID);
				} else {
					formData.append('title_id', personID);
				}
			}

			if (inputs.type === 'surgeon') {
				dispatch(
					postSurgeonAction({
						formData,
						callback: () => {
							toggleModal();
							refreshPeople();
						},
						noTrialID
					})
				);
			} else {
				dispatch(
					postStakeholderAction({
						formData,
						callback: () => {
							toggleModal();
							refreshPeople();
						},
						noTrialID
					})
				);
			}
		} else {
			setCurrentView((previousCurrentView) => previousCurrentView + 1);
		}
	};

	const handleGoBackOrCancel = (ev) => {
		ev.preventDefault();
		if (currentView === 0) {
			toggleModal();
		} else {
			setCurrentView((previousCurrentView) => previousCurrentView - 1);
		}
	};

	const [ currentView, setCurrentView ] = useState(0);
	let titleText = '';
	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleCustomFormSubmit,
		initialState: {
			firstName: '',
			lastName: '',
			primaryEmail: '',
			secondaryEmail: '',
			mobilePhone: '',
			workPhone: '',
			personTitleAndID: '',
			stakeholderTitleText: '',
			rating: '',
			profilePicture: '',
			type: 'surgeon'
		}
	});

	let currentViewShown;
	if (currentView === 0) {
		titleText = 'Add personal Details';
		currentViewShown = <FirstView handleInputChange={handleInputChange} inputs={inputs} />;
	} else if (currentView === 1) {
		titleText = 'Add professional details';
		currentViewShown = <SecondView handleInputChange={ handleInputChange } inputs={ inputs } addSurgeon={ addSurgeon } />;
	} else if (currentView === 2) {
		titleText = 'Select specialty';
		currentViewShown = <ThirdView handleInputChange={handleInputChange} inputs={inputs} />;
	}

	return (
		<form onSubmit={handleSubmit}>
			<h2 className={ classes['form__title'] }>{ titleText }</h2>
			{currentViewShown}
			<div className={classes['form__buttons']}>
				<button
					type="button"
					onClick={handleGoBackOrCancel}
					className={[
						classes['form__button'],
						classes[currentView === 0 ? 'form__button--cancel' : 'form__button--back']
					].join(' ')}
				>
					{currentView === 0 ? (
						<i className={[ classes['form__button-icon'], 'las la-times' ].join(' ')} />
					) : (
						<i className={[ classes['form__button-icon'], 'las la-angle-left' ].join(' ')} />
					)}

					{currentView === 0 ? 'cancel' : 'back'}
				</button>
				<div className={classes['form__progress-dots']}>
					<div
						className={
							currentView === 0 ? (
								[ classes['form__progress-dot'], classes['form__progress-dot--active'] ].join(' ')
							) : (
								classes['form__progress-dot']
							)
						}
					/>
					<div
						className={
							currentView === 1 ? (
								[ classes['form__progress-dot'], classes['form__progress-dot--active'] ].join(' ')
							) : (
								classes['form__progress-dot']
							)
						}
					/>
					{inputs.type === 'surgeon' ? (
						<div
							className={
								currentView === 2 ? (
									[ classes['form__progress-dot'], classes['form__progress-dot--active'] ].join(' ')
								) : (
									classes['form__progress-dot']
								)
							}
						/>
					) : null}
				</div>
				<button className={[ classes['form__button'], classes['form__button--submit'] ].join(' ')}>
					{currentView === 2 || (currentView === 1 && inputs.type === 'stakeholder') ? 'submit' : 'next'}{' '}
					<i className={[ classes['form__button-icon'], 'las la-angle-right' ].join(' ')} />
				</button>
			</div>
		</form>
	);
};

export default AddPersonalDetails;
