import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

export const productEducationAction = () => {
	return (dispatch, getState) => {
		let BU = JSON.parse(localStorage.getItem('BU'));

		dispatch({ type: ActionTypes.REQUEST_PRODUCT_EDUCATION });

		API()
			.post(`/api/v7/pages/adminpagelist`, { bu_id: BU })
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_PRODUCT_EDUCATION_SUCCESS,
					productEducation: res.data.records
				});
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.GET_PRODUCT_EDUCATION_ERROR, error: err });
			});
	};
};

export const productEducationSingleAction = (id) => {
	return (dispatch, getState) => {
		let BU = JSON.parse(localStorage.getItem('BU'));
		const parameter = {
			page_id: id,
			bu_id: BU
		};

		dispatch({ type: ActionTypes.RESET_PRODUCT_EDUCATION_SINGLE_IN_REDUCER });

		API()
			.post(`/api/v7/pages/adminpagecontentlist`, parameter)
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_PRODUCT_EDUCATION_SINGLE_SUCCESS,
					productEducationSingle: res.data.records
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_PRODUCT_EDUCATION_SINGLE_ERROR,
					productEducationSingleError: err
				});
			});
	};
};

export const getProductEducationSearchResultsAction = ({ productEducationID, searchValue }) => {
	return (dispatch) => {
		let BU = JSON.parse(localStorage.getItem('BU'));
		const parameter = {
			page_id: productEducationID,
			bu_id: BU,
			search: searchValue
		};

		API()
			.post(`/api/v7/pages/adminpagecontentlist`, parameter)
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_PRODUCT_EDUCATION_SEARCH_RESULTS_SUCCESS,
					productEducationSearchResults: res.data.records
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_PRODUCT_EDUCATION_SEARCH_RESULTS_ERROR,
					productEducationSearchResultsError: err
				});
			});
	};
};

export const getCompetitiveIntelSearchResultsAction = (searchValue) => {
	return (dispatch) => {
		let BU = JSON.parse(localStorage.getItem('BU'));
		const parameter = {
			bu_id: BU,
			search: searchValue
		};

		dispatch({ type: ActionTypes.REQUEST_COMPETITIVE_INTEL_SEARCH_RESULTS });

		API()
			.post(`/api/v7/content/admincontentlist`, parameter)
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_COMPETITIVE_INTEL_SEARCH_RESULTS_SUCCESS,
					competitiveIntelSearchResults: res.data.records
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_COMPETITIVE_INTEL_SEARCH_RESULTS_ERROR,
					competitiveIntelSearchResultsError: err
				});
			});
	};
};

export const getContentListAction = (currentPageNumber, sortBy, sortingDirection) => {
	return (dispatch, getState) => {
		let BU = JSON.parse(localStorage.getItem('BU'));
		console.log(localStorage.getItem('BU'));
		const parameters = {
			user_id: 299,
			content_type: 'folder',
			order_by: sortingDirection || 'ASC',
			page: currentPageNumber,
			sort_by: sortBy || 'content_name',
			sorting: sortBy || 'content_name',
			bu_id: BU
		};

		dispatch({ type: ActionTypes.REQUEST_CONTENT_LIST });

		API()
			.post(`/api/v7/content/mobilecontentlist`, parameters)
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_CONTENT_LIST_SUCCESS,
					currentPageNumber: currentPageNumber,
					contentList: res.data.records,
					totalRecords: res.data.total_records
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_CONTENT_LIST_ERROR, contentListError: 'Failed to load' });
			});
	};
};

export const getContentFolderListAction = ({ folderID, currentPageNumber }) => {
	return (dispatch, getState) => {
		let BU = JSON.parse(localStorage.getItem('BU'));

		const parameters = {
			user_id: 299,
			content_type: 'all',
			order_by: 'ASC',
			page: currentPageNumber,
			sort_by: 'content_name',
			folder_id: folderID,
			bu_id: BU
		};

		dispatch({ type: ActionTypes.REQUEST_CONTENT_FOLDER_LIST });

		API()
			.post(`/api/v7/content/mobilecontentlist`, parameters)
			.then((res) => {
				return dispatch({
					type: ActionTypes.GET_CONTENT_FOLDER_LIST_SUCCESS,
					currentPageNumber: currentPageNumber,
					competitiveIntelFolder: res.data.records,
					totalRecords: res.data.total_records
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_CONTENT_FOLDER_LIST_ERROR,
					competitiveIntelFolderError: 'Failed to load'
				});
			});
	};
};


export const resetCompetitiveIntelFolderInReducerAction = () => {
	return (dispatch) => {
		dispatch({ type: ActionTypes.RESET_COMPETIIVE_INTEL_FOLDER_IN_REDUCER });
	}
}
