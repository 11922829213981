import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../assets/css/app.css';

class Burger extends Component {
    state = { menuOpen: false }

    handleMenuClick () {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    handleLinkClick () {
        this.setState({ menuOpen: false });
    }

    render () {
        const styles =
        {
            burgerContainer: {
                position: "relative",
                zIndex: "40",
                display: "flex",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                paddingLeft: "25px",
                width: "100vw",
                height: "100vh",
                filter: this.state.menuOpen ? "blur(10px)" : null,
                transition: "filter .2s ease",
            },
            businessunit: {
                fontFamily: "EgyptienneURWLig",
                fontWeight: 100,
                fontSize: "1.2rem",
            },
            notification: {
                fontFamily: "EgyptienneURWLig",
                fontWeight: 100,
                fontSize: "1.2rem",
            },
            logout: {
                fontFamily: "EgyptienneURWLig",
                fontWeight: 100,
                fontSize: "1.3rem",
            }
        }
        const menu = [
            <div
                className="dashboard-menu__title preference inactive">NAVIGATION</div>,
            <Link to="/people" className="futurastdbold" >People</Link>,
            <Link to="/customers"  className="futurastdbold" >Customers</Link>,
            <div
                className="dashboard-menu__title preference inactive">PREFERENCES</div>,
            <Link to="/businessunit" className="businessunit" style={ styles.businessunit }>Business Unit Selection</Link>,/* 
            <Link to="/notification" className="dashboard-menu__item-notification" style={ styles.notification }>Notification Preferences</Link>, */
            <div className="dashboard-menu__line" />,
            <Link to="/auth" className="logout" style={ styles.logout } >Log Out</Link>
        ]
        const menuItems = menu.map((val, index) => {
            return (
                <MenuItem
                    key={ index }
                    delay={ `${ index * 0.1 }s` }
                    onClick={ () => { this.handleLinkClick(); } }>{ val }
                </MenuItem>
            )
        });

        return (
            <div>
                <div style={ styles.burgerContainer }>
                    <MenuButton open={ this.state.menuOpen } onClick={ () => this.handleMenuClick() } />
                </div>
                <Menu open={ this.state.menuOpen }>
                    { menuItems }
                </Menu>

            </div>
        )
    }
}

class MenuItem extends Component {
    state = { hover: false }

    handleHover () {
        this.setState({ hover: !this.state.hover });
    }

    render () {
        const styles = {
            container: {
                opacity: 0,
                animation: "1s appear forwards",
                animationDelay: this.props.delay,
            },
            menuItem: {
                fontWeight: "300",
                lineHeight: "1",
                margin: "0 15px",
                cursor: "pointer",
                transition: "color .1 ease-in-out",
                animation: ".2s appear forwards",
                animationDelay: this.props.delay
            },
        }
        return (
            <div
                className="dashboard-menu__item-container"
                style={ styles.container }>
                <div
                    className="dashboard-menu__item"
                    style={ styles.menuItem }
                    onMouseEnter={ () => { this.handleHover(); } }
                    onMouseLeave={ () => { this.handleHover(); } }
                    onClick={ this.props.onClick } >
                    { this.props.children }
                </div>
                <div style={ styles.line } />
            </div>
        )
    }
}

class Menu extends Component {
    state = {
        open: this.props.open ? this.props.open : false
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.open !== prevState.open)
        {
            return { open: nextProps.open };
        }
        else return null;
    }

    render () {
        const styles = {
            container: {
                position: "absolute",
                top: 0,
                height: this.state.open ? "100%" : 0,
                display: "flex",
                maxWidth: 900,
                flexDirection: "column",
                background: "#fff",
                opacity: 0,
                animation: "2s fadeIn forwards",
                zIndex: 2,
                textAlign: "left"
            },
            overlay: {
                backgroundColor: "#000000cc",
                opacity: ".8",
                width: 900,
                height: "100vh",
                animation: ".5s fadeIn forwards",
            }
        }
        return (
            <>
                <div style={ styles.container }>
                    { this.state.open ? <div style={ styles.overlay } /> : null }
                </div>
                <div
                    className={ this.state.open ? "dashboard-menu-container" : ("") }
                    style={ styles.container }>
                    { this.state.open ?
                        (<div
                            className="dashboard-menu"
                            style={ styles.menuList }>{ this.props.children }</div>
                        ) : null
                    }
                </div>
            </>
        )
    }
}

class MenuButton extends Component {
    state = {
        open: this.props.open ? this.props.open : false,
        color: this.props.color ? this.props.color : "#9d9d9d",
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.open !== prevState.open)
        {
            return { open: nextProps.open };
        }
        else return null;
    }


    handleClick () {
        this.setState({ open: !this.state.open });
    }

    render () {
        const styles = {
            container: {
                height: "48px",
                width: "48px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                padding: "4px"
            },
            line: {
                height: "2px",
                width: "18px",
                background: "black",
                transition: "all .1 ease",
            },
            lineTop: {
                transform: this.state.open ? "rotate(45deg)" : "none",
                transformOrigin: "top left",
                marginBottom: "4px",
            },
            lineMiddle: {
                opacity: this.state.open ? 0 : 1,
            },
            lineBottom: {
                transform: this.state.open ? "translateX(-1px) rotate(-45deg)" : "none",
                transformOrigin: "top left",
                marginTop: "4px",
            },
        }
        return (
            <div style={ styles.container }
                onClick={ this.props.onClick ? this.props.onClick :
                    () => { this.handleClick(); } }>
                <div style={ { ...styles.line, ...styles.lineTop } } />
                <div style={ { ...styles.line, ...styles.lineMiddle } } />
                <div style={ { ...styles.line, ...styles.lineBottom } } />
            </div>
        )
    }
};

export default Burger;

