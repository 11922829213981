import React, { Component } from "react";
import { connect } from 'react-redux';
import { getCaseAction, getSurgeonsForCreatingAndUpdatingACaseAction } from '../Store/actions/casesAction';
import { withRouter } from 'react-router-dom'
import CreateCaseModal from './Trial/CreateCaseModal/CreateCaseModal';

import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import StatsTrialsCases from "../Section/StatsTrialsCases";
import PeopleCases from "../Section/PeopleCases";

class TrialOrganizationCases extends Component {
    state = {
        createCaseModalIsOpen: false
    }

    toggleCreateCaseModal = () => {
        this.setState({
            createCaseModalIsOpen: !this.state.createCaseModalIsOpen
        });
    }

    componentDidMount () {
        const trialID = this.props.match.params.id;
        this.props.getCase(trialID);
        this.props.getSurgeonsForCreatingACase(trialID);
    }

    render () {
        let pageURL = window.location.href;
        let lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1).replace(/-/g, ' ');
        const trialSelected = lastURLSegment.replace(/\w\S*/g, w => (w.replace(/^\w/, c => c.toUpperCase())));
        const trialName = trialSelected.replace(/ /g, '-').toLowerCase();

        const { analytics, specialist_cases: specialistCases, case_count: caseCount, specialty_count: specialtyCount } = this.props.case;

        const { surgeonsForCreatingACase } = this.props;

        const { toggleCreateCaseModal } = this;
        const { createCaseModalIsOpen } = this.state;

        return (
            <React.Fragment>
                <div className="pb-80">
                    <div className="tab-title-main pb-2">
                        <Row>
                            <Col>
                                <div className="case-back mb-3">
                                    <i className="las la-angle-left" />
                                    <Link to={ "/trials/" + trialName }>{ trialName.replace(/-/g, ' ') }</Link>
                                </div>
                                <h4 className="section-title trials">Cases</h4>
                                <img
                                    src="/images/icon-plus.png"
                                    className="plus mt-2"
                                    alt="plus icon"
                                    onClick={ toggleCreateCaseModal } />
                                <div className="line-blk mb-2" />
                            </Col>
                        </Row>
                        <StatsTrialsCases caseInformation={ analytics } caseCount={ caseCount } specialtyCount={ specialtyCount } />
                    </div>
                    <PeopleCases specialistCases={ specialistCases } />
                </div>
                <CreateCaseModal
                    surgeonsForCreatingACase={ surgeonsForCreatingACase }
                    createCaseModalIsOpen={ createCaseModalIsOpen }
                    toggleCreateCaseModal={ toggleCreateCaseModal } />
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        case: state.cases.case,
        surgeonsForCreatingACase: state.cases.surgeonsForCreatingACase
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCase: (trialID) => {
            dispatch(getCaseAction(trialID))
        },

        getSurgeonsForCreatingACase: (trialID) => {
            dispatch(getSurgeonsForCreatingAndUpdatingACaseAction(trialID));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialOrganizationCases));