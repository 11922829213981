import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import _ from 'lodash';
import Search from '../../../../Utils/Search';
import Paginate from '../../../../Utils/Paginate';
import Pagination from '../../../../Components/Pagination';
import { withRouter } from 'react-router-dom';
import SurveysAndEvaluationsView from './Views/SurveysAndEvaluationsView';
import classes from './SurveysAndEvaluations.module.css';
import {
	getEvaluationsAction,
	getTemplatesAction,
	getCrushTemplatesAction
} from '../../../../Store/actions/evaluationsAction';
import TemplatesView from './Views/TemplatesView';
import { connect } from 'react-redux';
import Create from '../CreateOrEdit/Create';
import LoadMoreButton from '../../../../Components/LoadMoreButton/LoadMoreButton';
import NoResults from '../../../../Components/NoResults/NoResults';

class Evaluations extends Component {
	state = {
		currentView: 0,
		sortColumn: {
			path: 'template_name',
			order: 'asc'
		},

		searchValue: '',
		surveyCreateOrEditQuestionairePopupIsOpen: false
	};

	componentDidMount = () => {
		const params = new URLSearchParams(this.props.location.search);
		const section = params.get('section');
		if (section !== null) {
			this.handleTabView(parseInt(section));
		} else {
			params.append('section', 0);
			window.location.replace(window.location.pathname + '?' + params.toString());
		}
		//this.props.getEvaluations({ trialID: this.props.trialID, page: this.props.evaluationsPage });
	};

	handleSort = (path) => {
		const sortColumn = JSON.parse(JSON.stringify(this.state.sortColumn));

		if (sortColumn.order === 'asc') {
			this.setState({ sortColumn: { path, order: 'desc' } });
		} else if (sortColumn.order === 'desc') {
			this.setState({ sortColumn: { path, order: 'asc' } });
		}
	};

	handleInputChange = (event) => {
		this.setState({
			searchValue: event.currentTarget.value
		});
	};

	filterEvaluationsBySearch = () => {
		if (this.state.currentView === 0) {
			return this.props.evaluations.filter((evaluation) => {
				return evaluation.template_name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});
		} else if (this.state.currentView === 1) {
			return this.props.templates.filter((template) => {
				return template.template_name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});
		} else {
			return this.props.crushTemplates.filter((crushTemplate) => {
				return crushTemplate.template_name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});
		}
	};

	handlePageChange = (page, viewToPaginate) => {
		this.setState({
			[viewToPaginate]: page
		});
	};

	handleTabView = (currentView) => {
		switch (currentView) {
			case 0:
				this.props.getEvaluations({ trialID: this.props.trialID, page: this.props.evaluationsPage });
				break;
			case 1:
				this.props.getEvaluationTemplates({ trialID: this.props.trialID, page: this.props.templatesPage });
				break;
			case 2:
				this.props.getEvaluationCrushTemplates({
					trialID: this.props.trialID,
					page: this.props.crushTemplatesPage
				});
				break;
		}
		this.setState({ currentView: currentView });
	};

	chooseSection = (selectedSection) => {
		const params = new URLSearchParams(this.props.location.search);
		const section = params.get('section');
		if (!section) {
			params.append('section', selectedSection);
		} else {
			params.set('section', selectedSection);
		}

		window.location.replace(window.location.pathname + '?' + params.toString());
	};

	toggleSurveyCreateOrEditQuestionairePopup = () => {
		this.setState({
			surveyCreateOrEditQuestionairePopupIsOpen: !this.state.surveyCreateOrEditQuestionairePopupIsOpen
		});
	};

	loadMoreEvaluations = () => {
		this.props.getEvaluations({ trialID: this.props.trialID, page: this.props.evaluationsPage });

		this.setState({
			searchValue: ''
		});
	};

	loadMoreTemplates = () => {
		this.props.getEvaluationTemplates({ trialID: this.props.trialID, page: this.props.templatesPage });

		this.setState({
			searchValue: ''
		});
	};

	loadMoreCrushTemplates = () => {
		this.props.getEvaluationCrushTemplates({ trialID: this.props.trialID, page: this.props.crushTemplatesPage });

		this.setState({
			searchValue: ''
		});
	};

	render() {
		const {
			isFetchingEvaluations,
			evaluations,
			evaluationsPageLimitReached,
			evaluationsError,
			isFetchingEvaluationTemplates,
			templates,
			templatesPageLimitReached,
			templatesError,
			isFetchingEvaluationCrushTemplates,
			crushTemplates,
			crushTemplatesPageLimitReached,
			crushTemplatesError,
			evaluationResults,
			evaluationResultsPageLimitReached,
			evaluationResultsError
		} = this.props;

		let filterEvaluationsBySearch = this.filterEvaluationsBySearch();
		console.log(!templatesPageLimitReached,
			templates.length > 0,
			isFetchingEvaluationTemplates)
		return (
			<React.Fragment>
				<Container className={classes['tab-title-main']}>
					<Row>
						<Col>
							<div className="d-flex justify-content-between align-items-center">
								<h4 className="section-title trials">Evaluations</h4>
								<button
									className="button-open-modal"
									onClick={this.toggleSurveyCreateOrEditQuestionairePopup}
								>
									<img
										src="/images/icon-plus.png"
										className="button-open-modal__image"
										alt="plus symbol"
									/>
								</button>
							</div>
						</Col>
					</Row>
					<div className="nav-pagelink">
						<div
							onClick={() => this.chooseSection(0)}
							className={0 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							Evaluations
						</div>
						<div
							onClick={() => this.chooseSection(1)}
							className={1 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							My Templates
						</div>
						<div
							onClick={() => this.chooseSection(2)}
							className={2 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							Crush Templates
						</div>
						{/* <div
							onClick={() => this.handleTabView(3)}
							className={3 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							Results
						</div> */}
					</div>
					<div className="hr-bk-expand-ml-mr-15" />
					<Search searchingValue={this.state.searchValue} handleInputChange={this.handleInputChange} />
				</Container>

				{this.state.currentView === 0 ? (
					<React.Fragment>
						<SurveysAndEvaluationsView
							surveysAndEvaluations={filterEvaluationsBySearch}
							onSort={this.handleSort}
							type="evaluations"
							isFetchingEvaluations={isFetchingEvaluations}
						/>
						{filterEvaluationsBySearch.length === 0 && !isFetchingEvaluations ? (
							<NoResults message="Add templates from crush templates to your trial" />
						) : null}
						{!evaluationsPageLimitReached &&
						evaluations.length > 0 &&
						!isFetchingEvaluations && <LoadMoreButton handleClick={this.loadMoreEvaluations} />}
					</React.Fragment>
				) : null}

				{this.state.currentView === 1 ? (
					<React.Fragment>
						<TemplatesView
							templates={filterEvaluationsBySearch}
							onSort={this.handleSort}
							view="template-evaluations"
							isFetchingEvaluationTemplates={isFetchingEvaluationTemplates}
						/>
						{filterEvaluationsBySearch.length === 0 && !isFetchingEvaluationTemplates ? (
							<NoResults />
						) : null}
						{!templatesPageLimitReached &&
						templates.length > 0 &&
						!isFetchingEvaluationTemplates && <LoadMoreButton handleClick={this.loadMoreTemplates} />}
					</React.Fragment>
				) : null}

				{this.state.currentView === 2 ? (
					<React.Fragment>
						<TemplatesView
							templates={filterEvaluationsBySearch}
							onSort={this.handleSort}
							view="template-evaluations"
							isFetchingEvaluationCrushTemplates={isFetchingEvaluationCrushTemplates}
						/>
						{filterEvaluationsBySearch.length === 0 && !isFetchingEvaluationCrushTemplates ? (
							<NoResults />
						) : null}
						{!crushTemplatesPageLimitReached &&
						crushTemplates.length > 0 &&
						!isFetchingEvaluationCrushTemplates && (
							<LoadMoreButton handleClick={this.loadMoreCrushTemplates} />
						)}
					</React.Fragment>
				) : null}

				{this.state.surveyCreateOrEditQuestionairePopupIsOpen ? (
					<Create
						type="evaluation"
						surveyCreateOrEditQuestionairePopupIsOpen={this.state.surveyCreateOrEditQuestionairePopupIsOpen}
						toggleSurveyCreateOrEditQuestionairePopup={this.toggleSurveyCreateOrEditQuestionairePopup}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFetchingEvaluations: state.evaluations.isFetchingEvaluations,
		evaluations: state.evaluations.evaluations,
		evaluationsPage: state.evaluations.evaluationsPage,
		evaluationsPageLimitReached: state.evaluations.evaluationsPageLimitReached,
		evaluationsError: state.evaluations.evaluationsError,

		isFetchingEvaluationTemplates: state.evaluations.isFetchingEvaluationTemplates,
		templates: state.evaluations.templates,
		templatesPage: state.evaluations.templatesPage,
		templatesPageLimitReached: state.evaluations.templatesPageLimitReached,
		templatesError: state.evaluations.templatesError,

		isFetchingEvaluationCrushTemplates: state.evaluations.isFetchingEvaluationCrushTemplates,
		crushTemplates: state.evaluations.crushTemplates,
		crushTemplatesPage: state.evaluations.crushTemplatesPage,
		crushTemplatesPageLimitReached: state.evaluations.crushTemplatesPageLimitReached,
		crushTemplatesError: state.evaluations.crushTemplatesError,

		evaluationResults: state.evaluations.evaluationResults,
		evaluationResultsPage: state.evaluations.evaluationResultsPage,
		evaluationResultsPageLimitReached: state.evaluations.evaluationResultsPageLimitReached,
		evaluationResultsError: state.evaluations.evaluationResultsError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvaluations: ({ trialID, page }) => {
			dispatch(getEvaluationsAction({ trialID, page }));
		},

		getEvaluationTemplates: ({ trialID, page }) => {
			dispatch(getTemplatesAction({ trialID, page }));
		},

		getEvaluationCrushTemplates: ({ trialID, page }) => {
			dispatch(getCrushTemplatesAction({ trialID, page }));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Evaluations));
