import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

import axios from 'axios';

import { getPeopleAction as getPeopleActionFromPeopleActions } from './peopleActions';

export const getTrialsAction = ({ currentPageNumber, type }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			sort_by: 'last_viewed',
			order_by: 'ASC',
			page: currentPageNumber,
			search: '',
			trial_status: type
		};

		dispatch({ type: ActionTypes.REQUEST_TRIALS });

		API()
			.post(`api/v7/trial/userstrials`, parameters)
			.then((res) => {
				if (res.data.success === '1') {
					return dispatch({
						type: ActionTypes.GET_TRIALS_SUCCESS,
						trials: res.data.data || [],
						currentPageNumber: currentPageNumber
					});
				}
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_TRIALS_ERROR, trialsError: 'there was an error fetching trials!' });
			});
	};
};

export const getTrialStatisticsAction = (trialID) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID
		};

		dispatch({ type: ActionTypes.REQUEST_TRIAL_STATISTICS });

		API()
			.post('api/v7/trial/statistics', parameters)
			.then((res) => {
				console.log(res);
				return dispatch({ type: ActionTypes.GET_TRIAL_STATISTICS_SUCCESS, trialStatistics: res.data.data });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.GET_TRIAL_STATISTICS_ERROR, trialStatisticsError: error.message });
			});
	};
};

export const deleteTrialAction = (trialID) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID
		};

		API()
			.post('/api/v7/trial/delete', parameters)
			.then((res) => {
				return dispatch({ type: ActionTypes.DELETE_TRIAL_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.DELETE_TRIAL_ERROR, deleteTrialError: err.message });
			});
	};
};

export const getTrialDetailAction = (trialID) => {
	return (dispatch, getState) => {
		const filter = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID
		};

		dispatch({ type: ActionTypes.REQUEST_TRIAL_DETAILS });

		API()
			.post(`api/v7/trial/detail`, filter)
			.then((res) => {
				if (res.data.success === '0')
					return dispatch({ type: ActionTypes.GET_TRIAL_DETAIL_ERROR, trialError: '0' });
				return dispatch({ type: ActionTypes.GET_TRIAL_DETAIL_SUCCESS, trialDetail: res.data.data });
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_TRIAL_DETAIL_ERROR,
					trialDetailError: 'there was an error fetching a trial'
				});
			});
	};
};

export const getTrialMainAction = (trialID) => {
	return (dispatch, getState) => {
		const filter = {
			user_id: localStorage.getItem('user_id'),
			sort_by: 'name',
			order_by: 'ASC',
			page: 1,
			trial_id: trialID,
			search: ''
		};

		API()
			.post(`api/v7/trial/main`, filter)
			.then(function(res) {
				console.log(res);
				if (res.data.success === '0')
					return dispatch({ type: ActionTypes.GET_TRIAL_MAIN_ERROR, trialMainError: '0' });
				return dispatch({ type: ActionTypes.GET_TRIAL_MAIN_SUCCESS, trialMain: res.data.data || {} });
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_TRIAL_MAIN_ERROR,
					trialMainError: 'there was an error fetching a trial!'
				});
			});
	};
};

export const getTrialPeopleAction = ({ trialID, currentPageNumber = 1 }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			type: 'all',
			trial_id: trialID,
			page: currentPageNumber,
			search: ''
		};

		dispatch({ type: ActionTypes.REQUEST_TRIAL_PEOPLE });

		API()
			.post('api/v7/trial/gettrialpeople', parameters)
			.then((res) => {
				if (res.data.success === '0')
					return dispatch({ type: ActionTypes.GET_TRIAL_PEOPLE_ERROR, trialPeopleError: res.data.message });
				return dispatch({
					type: ActionTypes.GET_TRIAL_PEOPLE_SUCCESS,
					trialPeople: res.data.data || [],
					currentPageNumber: currentPageNumber
				});
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.GET_TRIAL_PEOPLE_ERROR, trialPeopleError: err.message });
			});
	};
};

export const getPeopleAction = ({
	search = '',
	type = 'all',
	sortBy = 'created_date',
	orderBy = 'DESC',
	currentPageNumber = 1
}) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			type: type,
			search: search,
			sort_by: sortBy,
			order_by: orderBy,
			page: currentPageNumber
		};

		dispatch({ type: ActionTypes.REQUEST_TRIAL_PEOPLE });

		API()
			.post('api/v7/people/getpeople', parameters)
			.then((res) => {
				if (res.data.message === '0')
					return dispatch({ type: ActionTypes.GET_TRIAL_PEOPLE_ERROR, trialPeopleError: res.data.message });
				return dispatch({
					type: ActionTypes.GET_TRIAL_PEOPLE_SUCCESS,
					trialPeople: res.data.data.resultData || [],
					currentPageNumber: currentPageNumber
				});
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.GET_TRIAL_PEOPLE_ERROR, trialPeopleError: err.message });
			});
	};
};

export const getTrialPeopleStakeholderOrSurgeonAction = (stakeholderOrSurgeonID, type) => {
	return (dispatch, getState) => {
		let parameters = {
			user_id: localStorage.getItem('user_id')
		};
		type = type.substring(0, type.length - 1);

		if (type === 'stakeholder') {
			parameters.stakeholder_id = stakeholderOrSurgeonID;
		} else if (type === 'surgeon') {
			parameters.surgeon_id = stakeholderOrSurgeonID;
		}

		API()
			.post(`api/v7/people/view${type}`, parameters)
			.then((res) => {
				if (res.data.message === 'No record found.')
					return dispatch({
						type: ActionTypes.GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_ERROR,
						trialPeopleStakeholderOrSurgeonError: res.data.message
					});
				return dispatch({
					type: ActionTypes.GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_SUCCESS,
					trialPeopleStakeholderOrSurgeon: res.data.data
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_ERROR,
					trialPeopleStakeholderOrSurgeonError: err.message
				});
			});
	};
};

export const getTeammatesForSearchAction = (input) => {
	return (dispatch, getState) => {
		const filter = {
			user_id: localStorage.getItem('user_id'),
			type: 'all',
			search: input
		};

		API()
			.post('/api/v7/user/APIUserList', filter)
			.then((res) => {
				if (res.data.message.includes('No record found.')) {
					return dispatch({
						type: ActionTypes.GET_TEAMMATES_FOR_SEARCH_ERROR,
						teammatesForSearchError: 'No results'
					});
				}
				return dispatch({
					type: ActionTypes.GET_TEAMMATES_FOR_SEARCH_SUCCESS,
					teammatesForSearch: res.data.records
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_TEAMMATES_FOR_SEARCH_ERROR,
					teammatesForSearchError: 'there was an error!'
				});
			});
	};
};

export const postTeammateAction = (person, trialID) => {
	return (dispatch, getState) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			first_name: person.first_name,
			last_name: person.last_name,
			email: person.email
		};

		API()
			.post('/api/v7/trial/addmyteam', parameters)
			.then((res) => {
				if (res.data.success === '0') {
					return dispatch({ type: ActionTypes.POST_TEAMMATE_ERROR, postTeammatesError: res.data.message });
				}
				return dispatch({ type: ActionTypes.POST_TEAMMATE_SUCCESS, postTeammates: res.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.POST_TEAMMATE_ERROR, postTeammatesError: 'there was an error!' });
			});
	};
};

export const deleteTeammateAction = ({ trialID, teammateID }, toggleRemoveUserModal) => {
	return (dispatch, getState) => {
		const parameters = {
			trial_id: trialID,
			id: teammateID,
			user_id: localStorage.getItem('user_id'),
			is_delete: '1'
		};

		API()
			.post('/api/v7/trial/statusmyteam', parameters)
			.then((res) => {
				dispatch(getTeammatesAction(trialID));
				toggleRemoveUserModal();
				return dispatch({ type: ActionTypes.DELETE_TEAMMATE_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.DELETE_TEAMMATE_ERROR, deleteTeammateError: err.message });
			});
	};
};

export const getTeammatesAction = (trialID) => {
	return (dispatch, getState) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id')
		};

		API()
			.post('/api/v7/trial/myteam', parameters)
			.then((res) => {
				if (res.data.message.includes('No record found.')) {
					return dispatch({ type: ActionTypes.GET_TEAMMATES_ERROR, teammatesError: '' });
				}
				return dispatch({ type: ActionTypes.GET_TEAMMATES_SUCCESS, teammates: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_TEAMMATES_ERROR, teammatesError: 'there was an error!' });
			});
	};
};

export const getStakeholderTitlesAction = (trialID) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id')
		};

		API()
			.post('/api/v7/trial/stakeholderstitle', parameters)
			.then((res) => {
				return dispatch({
					type: ActionTypes.GET_STAKEHOLDER_TITLES_SUCCESS,
					stakeholderTitlesArray: res.data.data
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_STAKEHOLDER_TITLES_ERROR,
					stakeholderTitlesError: err.message
				});
			});
	};
};

export const getSpecialitiesAction = (trialID) => {
	let parameters = {
		user_id: localStorage.getItem('user_id')
	};

	if (trialID) {
		parameters['trial_id'] = trialID;
	}

	return (dispatch) => {
		dispatch({ type: ActionTypes.REQUEST_SPECIALITIES });

		API()
			.get('/api/v7/trial/specialities', parameters)
			.then((res) => {
				return dispatch({ type: ActionTypes.GET_SPECIALITIES_SUCCESS, specialitiesArray: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_SPECIALITIES_ERROR, specialitiesError: err.message });
			});
	};
};

export const postSpecialitiesAction = ({ trialID, specialities }) => {
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		trial_id: trialID,
		specialities: specialities
	};

	return (dispatch) => {
		API()
			.post('/api/v7/trial/savespecialities', parameters)
			.then((res) => {
				dispatch(getTrialMainAction(trialID));
				return dispatch({ type: ActionTypes.POST_SPECIALITIES_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.POST_SPECIALITIES_ERROR, postSpecialitiesError: err.message });
			});
	};
};

export const deleteSpecialitiesAction = ({ trialID, specialities }) => {
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		trial_id: trialID,
		specialities: specialities
	};

	return (dispatch) => {
		API()
			.post('/api/v7/trial/deletespecialities', parameters)
			.then((res) => {
				dispatch(getTrialMainAction(trialID));
				return dispatch({ type: ActionTypes.DELETE_SPECIALITIES_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.DELETE_SPECIALITIES_ERROR, deleteSpecialitiesError: err.message });
			});
	};
};

export const getCompetitorsAction = (trialID) => {
	let BU = JSON.parse(localStorage.getItem('BU'));
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		trial_id: trialID,
		bu_id: BU
	};

	return (dispatch) => {
		dispatch({ type: ActionTypes.REQUEST_COMPETITORS });

		API()
			.post('/api/v7/trial/competitors', parameters)
			.then((res) => {
				return dispatch({ type: ActionTypes.GET_COMPETITORS_SUCCESS, competitorsArray: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_COMPETITORS_ERROR, competitorsError: err.message });
			});
	};
};

export const postCompetitorsAction = ({ trialID, competitorID }) => {
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		trial_id: trialID,
		competitor_id: competitorID
	};

	return (dispatch) => {
		API()
			.post('/api/v7/trial/savecompetitors', parameters)
			.then((res) => {
				dispatch(getCompetitorsAction(trialID));
				return dispatch({ type: ActionTypes.POST_COMPETITORS_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.POST_COMPETITORS_ERROR, postCompetitorsError: err.message });
			});
	};
};

export const deleteCompetitorsAction = ({ trialID, competitorID }) => {
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		trial_id: trialID,
		competitor_id: competitorID
	};

	return (dispatch) => {
		API()
			.post('/api/v7/trial/deletecompetitors', parameters)
			.then((res) => {
				dispatch(getCompetitorsAction(trialID));
				return dispatch({ type: ActionTypes.DELETE_COMPETITORS_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.DELETE_COMPETITORS_ERROR, deleteCompetitorsError: err.message });
			});
	};
};

export const postSurgeonAction = ({ formData, callback, noTrialID = false }) => {
	return (dispatch, getState) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		API()
			.post('/api/v7/trial/savesurgeon', formData, config)
			.then((res) => {
				if (noTrialID) {
					dispatch(getPeopleActionFromPeopleActions({}));
				} else {
					dispatch(getTrialPeopleAction({ trialID: formData.get('trial_id') }));
				}

				if (callback) {
					callback();
				}

				return dispatch({ type: ActionTypes.POST_SURGEONS_SUCCESS, createdPersonID: res.data.data.id });
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_SURGEONS_ERROR, postSurgeonError: err.message });
			});
	};
};

export const postPeopleSurgeonAction = (formData) => {
	return (dispatch, getState) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		API()
			.post('/api/v7/trial/savesurgeon', formData, config)
			.then((res) => {
				dispatch(getPeopleAction({ trialID: '' }));
				return dispatch({ type: ActionTypes.POST_SURGEONS_SUCCESS });
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_SURGEONS_ERROR, postSurgeonError: err.message });
			});
	};
};

export const deleteSurgeonAction = ({ trialID, personID, callback }) => {
	const parameters = {
		trial_id: trialID,
		surgeon_id: personID
	};

	return (dispatch) => {
		API()
			.post('/api/v7/trial/deletepeoplesurgeon', parameters)
			.then((res) => {
				dispatch(getTrialPeopleAction({ trialID: trialID }));
				if (callback) callback();
				return dispatch({ type: ActionTypes.DELETE_SURGEON_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.DELETE_SURGEON_ERROR, deleteSurgeonError: err.message });
			});
	};
};

export const postStakeholderAction = ({ formData, callback, noTrialID = false }) => {
	return (dispatch, getState) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		API()
			.post('/api/v7/trial/savestakeholder', formData, config)
			.then((res) => {
				if (noTrialID)
				{
					dispatch(getPeopleActionFromPeopleActions({}));
				} else
				{
					dispatch(getTrialPeopleAction({ trialID: formData.get('trial_id') }));
				}

				if (callback) {
					callback();
				}
				return dispatch({ type: ActionTypes.POST_STAKEHOLDER_SUCCESS, createdPersonID: res.data.data.id });
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_STAKEHOLDER_ERROR, postStakeholderError: err.message });
			});
	};
};

export const postPeopleStakeholderAction = (formData) => {
	return (dispatch, getState) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		API()
			.post('/api/v7/trial/savestakeholder', formData, config)
			.then((res) => {
				dispatch(getPeopleAction({ trialID: '' }));
				return dispatch({ type: ActionTypes.POST_STAKEHOLDER_SUCCESS });
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_STAKEHOLDER_ERROR, postStakeholderError: err.message });
			});
	};
};

export const deleteStakeholderAction = ({ trialID, personID, callback }) => {
	const parameters = {
		trial_id: trialID,
		stakeholder_id: personID
	};

	return (dispatch) => {
		API()
			.post('/api/v7/trial/deletepeoplestakeholder', parameters)
			.then((res) => {
				dispatch(getTrialPeopleAction({ trialID: trialID }));
				if (callback) callback();
				return dispatch({ type: ActionTypes.DELETE_STAKEHOLDER_SUCCESS });
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.DELETE_STAKEHOLDER_ERROR, deleteStakeholderError: err.message });
			});
	};
};

export const getTrialsAvailableCustomers = () => {
	const parameters = {
		user_id: localStorage.getItem('user_id')
	};
	return (dispatch) => {
		dispatch({ type: ActionTypes.REQUEST_GET_TRIALS_AVAILABLE_CUSTOMERS });

		API()
			.post('/api/v7/trial/accounts', parameters)
			.then((res) => {
				return dispatch({
					type: ActionTypes.GET_TRIALS_AVAILABLE_CUSTOMERS_SUCCESS,
					availableCustomers: res.data.data || []
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_TRIALS_AVAILABLE_CUSTOMERS_ERROR,
					availableCustomersError: err.message
				});
			});
	};
};

export const getCustomerDetailsAction = ({ customerId }) => {
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		account_id: customerId
	};
	return (dispatch) => {
		API()
			.post('/api/v7/trial/detailaccount', parameters)
			.then((res) => {
				console.log(res);
				return dispatch({
					type: ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS,
					customerDetails: res.data.data
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_CUSTOMER_DETAILS_ERROR,
					customerDetailsError: err.message
				});
			});
	};
};

export const createCustomerAction = (
	{ name, account_number, address, address2, city, state_id, zipcode, website, phone, account_id },
	onSuccessCallback
) => {
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		name: name,
		account_number: account_number,
		address: address,
		address2: address2,
		city: city,
		state_id: state_id,
		zipcode: zipcode,
		website: website,
		phone: phone
	};

	if (account_id) {
		parameters.account_id = account_id;
	}

	return (dispatch) => {
		API()
			.post('/api/v7/trial/createaccount', parameters)
			.then((res) => {
				if (onSuccessCallback) {
					onSuccessCallback();
				}
				return dispatch(getTrialsAvailableCustomers({ user_id: localStorage.getItem('user_id') }));
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_CUSTOMER_DETAILS_ERROR,
					customerDetailsError: err.message
				});
			});
	};
};

export const postNewTrial = (trialDetails, callback) => {
	let BU = JSON.parse(localStorage.getItem('BU'));
	const parameters = {
		user_id: localStorage.getItem('user_id'),
		customer_id: trialDetails.customer_id,
		competitor_id: trialDetails.competitor_id,
		specialty_id: trialDetails.specialty_id,
		trial_name: trialDetails.trial_name,
		start_date: trialDetails.start_date,
		end_date: trialDetails.end_date,
		budget_cycle: trialDetails.budget_cycle,
		expected_purchase: trialDetails.expected_purchase,
		people_id: trialDetails.people_id,
		bu_id: BU
	};
	return (dispatch) => {
		API()
			.post('/api/v7/trial/TrialAdd', parameters)
			.then((res) => {
				if (callback) callback(res.data.trial_id);
				return dispatch({
					type: ActionTypes.GET_TRIALS_AVAILABLE_CUSTOMERS_SUCCESS
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_TRIALS_AVAILABLE_CUSTOMERS_ERROR,
					availableCustomersError: err.message
				});
			});
	};
};

export const resetTrialsReducerAction = () => {
	return (dispatch) => {
		dispatch({ type: ActionTypes.RESET_TRIALS_REDUCER });
	};
};

export const putTrialStatusAction = ({ trialID, trialStatus, toggleModal }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			trial_status: trialStatus
		};

		API()
			.post('api/v7/trial/status', parameters)
			.then((res) => {
				console.log(res);
				toggleModal();
				dispatch(getTrialDetailAction(trialID));
				return dispatch({ type: ActionTypes.PUT_TRIAL_STATUS_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.PUT_TRIAL_STATUS_ERROR, putTrialStatusError: error.message });
			});
	};
};

export const putTrialInformationAction = ({
	trialID,
	trial_name,
	start_date,
	end_date,
	budget_cycle,
	expected_purchase
}) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			trial_name: trial_name,
			start_date: start_date,
			end_date: end_date,
			budget_cycle: budget_cycle,
			expected_purchase: expected_purchase
		};

		API()
			.post('api/v7/trial/information', parameters)
			.then((res) => {
				dispatch(getTrialMainAction(trialID));
				return dispatch({ type: ActionTypes.PUT_TRIAL_INFORMATION_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.PUT_TRIAL_INFORMATION_ERROR,
					putTrialInformationError: error.message
				});
			});
	};
};

export const getStatesAction = () => {
	return (dispatch) => {
		API()
			.get('api/v7/index/state')
			.then((res) => {
				return dispatch({ type: ActionTypes.GET_STATES_SUCCESS, getStates: res.data.data });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.GET_STATES_ERROR, getStatesError: error.message });
			});
	};
};

export const resetCreatedPersonIDAction = () => {
	return (dispatch) => {
		dispatch({ type: ActionTypes.RESET_CREATED_PERSON_ID_IN_TRIALS_REDUCER });
	};
};