import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import { getTrialsAction } from '../Store/actions/trialsAction';
import { connect } from 'react-redux';

import { getStatus } from '../Data/database';

import Search from '../Utils/Search';
import TableTrials from '../Components/TableTrials';
import { ListGroupTrials } from '../Components/ListGroup';
import List from '../Components/List/List';
import LoadMoreButton from '../Components/LoadMoreButton/LoadMoreButton';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import NoResults from '../Components/NoResults/NoResults';

class Trials extends Component {
	state = {
		pageCounterForAPI: 1,
		selectedCategory: getStatus()[1],
		currentlySearching: false,
		searchingValue: '',
		typeOfTrialToShow: 'active'
	};

	static getDerivedStateFromProps(props, state) {
		if (state.currentlySearching) {
			return null;
		}
		
		return null;
	}

	componentDidMount() {
		this.props.getTrials({ currentPageNumber: this.state.pageCounterForAPI, type: this.state.typeOfTrialToShow });
	}

	loadMoreTrialsFromAPI = () => {
		this.setState(
			(prevState) => {
				return {
					pageCounterForAPI: prevState.pageCounterForAPI + 1,
					currentlySearching: false,
					searchingValue: ''
				};
			},
			() => {
				this.props.getTrials({
					currentPageNumber: this.state.pageCounterForAPI,
					type: this.state.typeOfTrialToShow
				});
			}
		);
	};

	filterTrialsBySearch = () => {
		if (!this.props.trialsArrayFromAPI) {
			return []
		}

		return this.props.trialsArrayFromAPI.filter(trial => {
			return trial.trial_name.toLowerCase().includes(this.state.searchingValue.toLowerCase().trim())
		});
	};

	handleInputChange = (event) => {
		this.setState({
			currentlySearching: true,
			searchingValue: event.currentTarget.value
		});
	}

	render() {
		const { selectedCategory } = this.state;
		let filterTrialsBySearch = this.filterTrialsBySearch();

		const trials = filterTrialsBySearch
			? filterTrialsBySearch.map(trial => {
					let status = '';
					let active = null;
					if (trial.trial_status === 'active') {
						status = { id: '991', name: 'Active trials' };
						active = true;
					} else {
						status = { id: '992', name: 'Archived trials' };
						active = false;
					}

					return {
						id: trial.trial_id,
						trialName: trial.trial_name,
						createdDate: trial.created_date,
						name: trial.name,
						active: active,
						status: status
					};
				})
			: [];
		const status = getStatus();

		const setSelectedCategory = (selectedCategory) => this.setState({ selectedCategory });

		const handleCategorySelect = (category) => {
			if (category.name === 'Active trials') {
				this.setState(
					{
						typeOfTrialToShow: 'active',
						pageCounterForAPI: 1
					},
					() => {
						this.props.getTrials({
							currentPageNumber: this.state.pageCounterForAPI,
							type: this.state.typeOfTrialToShow
						});
					}
				);
			} else if (category.name === 'Archived trials') {
				this.setState(
					{
						typeOfTrialToShow: 'archived',
						pageCounterForAPI: 1
					},
					() => {
						this.props.getTrials({
							currentPageNumber: this.state.pageCounterForAPI,
							type: this.state.typeOfTrialToShow
						});
					}
				);
			} else {
				this.setState(
					{
						typeOfTrialToShow: '',
						pageCounterForAPI: 1
					},
					() => {
						this.props.getTrials({
							currentPageNumber: this.state.pageCounterForAPI,
							type: this.state.typeOfTrialToShow
						});
					}
				);
			}
			setSelectedCategory(category);
		};

		const filteredTrials =
			selectedCategory && selectedCategory.id
				? trials.filter((i) => i.status.id === selectedCategory.id)
				: trials;

		return (
			<div>
				<Container className="tab-title-main">
					<Link to="/trial/create">
						<img src="images/icon-plus.png" className="plus mt-2" alt="plus sign icon" />
					</Link>

					<h1 className="section-title">Trials</h1>
					<div className="nav-pagelink">
						<ListGroupTrials
							items={status}
							onItemSelect={handleCategorySelect}
							selectedItem={selectedCategory}
						/>
					</div>
					<div className="hr-bk-expand-ml-mr-15"></div>
					<Search searchingValue={ this.state.searchingValue } handleInputChange={ this.handleInputChange } />
				</Container>
				<Container>
					<List list={ filteredTrials } actions={ null } view="trials" removeSort={ true } isLoading={ this.props.isFetchingTrials } />
					
					{ this.props.trialsError &&
						!this.props.isFetchingTrials ? (
						<ErrorMessage message={ this.props.trialsError } />
					)  : (null) }

					{ filterTrialsBySearch.length === 0 && 
					!this.props.isFetchingTrials && 
					!this.props.trialsError ? (
						<NoResults message="no trials" />
						) : (null) }
			
					{ !this.props.isLastPageInTrials && 
					!this.props.isFetchingTrials &&
					!this.props.trialsError ? (
						<LoadMoreButton handleClick={ this.loadMoreTrialsFromAPI } />
					) : null}
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		trialsArrayFromAPI: state.trials.trials,
		isFetchingTrials: state.trials.isFetchingTrials,
		trialsError: state.trials.trialsError,
		isLastPageInTrials: state.trials.isLastPageInTrials
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTrials: ({ currentPageNumber, type }) => {
			dispatch(getTrialsAction({ currentPageNumber, type }));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Trials);
