import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getRespondentsForSurveyAction, getSurveyTemplateAction, getCompletedSurveyAction,submitSurveyResponses,getSurveyemplateAction,downloadIndividualSurveyResults } from '../../../../Store/actions/surveysAction';
import { getRespondentsForEvaluationAction, getCompletedEvaluationAction, submitEvaluationResponses,getEvaluationTemplateAction,downloadIndividualResults } from '../../../../Store/actions/evaluationsAction';
import FormQuestions from "../FormQuestions/FormQuestions";

const TrialSurveyEvalResults = (props) => {
    const {type, trialID, id, personID, personType} = props.match.params;
    const singularType = type.endsWith('s') ? type.substring(0, type.length-1): type;
    const { getEvaluation, getSurvey, getEvaluationRespondents,getSurveyRespondents,getEvaluationTemplate,getSurveyTemplate ,downloadResultsReport,downloadSurveyResultsReport} = props;
    const selectedTemplate = props[singularType];
    const selectedPerson = props[`${type}Respondents`]?.find(person => person.id === personID);
    const submittedSurveyEval = props[`submitted_${type}`] || {};

    useEffect(()=> {
        if(type === 'survey') {
            getSurvey({trialID: trialID, templateID: id, personID: personID, personType: personType});
            getSurveyTemplate({trialID: trialID, templateID: id})
            getSurveyRespondents({trialID: trialID, templateID: id})
        }   else {
            getEvaluation({trialID: trialID, templateID: id, personID: personID, personType: personType});
            getEvaluationTemplate({trialID: trialID, templateID: id})
            getEvaluationRespondents({trialID: trialID, templateID: id})
        }
    } , !selectedTemplate || selectedTemplate.template_id === "");
       
    return (
        <div className="pb-5">
            <div>
                <div className="tab-title-main">
                    <h4 className="section-title-survey">{selectedTemplate.template_name} </h4>
                    <div className="line-blk-shrink mb-1" />
                    <p>
                        {selectedTemplate.question_list.length} questions<br/>Completed: 
                    {submittedSurveyEval.created_date}
                    </p>
                    <div className="tab-bar">
                        <i className="las la-file-export square-white" />
                        <div className="tab-bar-text" onClick={()=>{
                            if(type === 'survey') {
                                downloadSurveyResultsReport({trialID: trialID, templateID: id, peopleID: personID, peopleType: personType});
                            } else {
                                downloadResultsReport({trialID: trialID, templateID: id, peopleID: personID, peopleType: personType});
                            }
                        }}>Export
                                <span><img src="/images/icon-arrow.jpg" className="angle2" alt="" /></span>
                        </div>
                    </div>

                </div>
                <br/>
                <FormQuestions questionsArray={selectedTemplate.question_list}answeredQuestionsArray={submittedSurveyEval.evaluation_data}/>
            </div >
        </div>
    );
}
const mapStateToProps = (state) => {
	return {
        surveyRespondents: state.surveys.surveysRespondents,
        evaluationsRespondents: state.evaluations.evaluationsRespondents,
        submitted_evaluation: state.evaluations.completedEvaluation,
        submitted_survey: state.surveys.completedSurvey
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

		getEvaluationTemplate: (parameters) => {
			dispatch(getEvaluationTemplateAction(parameters));
		},
		getSurveyTemplate: (parameters) => {
			dispatch(getSurveyTemplateAction(parameters));
		},

		getEvaluation: (parameters) => {
			dispatch(getCompletedEvaluationAction(parameters));
		},
		getSurvey: (parameters) => {
			dispatch(getCompletedSurveyAction(parameters));
		},

		getEvaluationRespondents: (parameters) => {
			dispatch(getRespondentsForEvaluationAction(parameters));
		},
		getSurveyRespondents: (parameters) => {
			dispatch(getRespondentsForSurveyAction(parameters));
        },

        submitEvaluation : (parameters) => {
            dispatch(submitEvaluationResponses(parameters))
        }
,
        downloadResultsReport: (parameters) => {
            dispatch(downloadIndividualResults(parameters));
        },
        downloadSurveyResultsReport: (parameters) => {
            dispatch(downloadIndividualSurveyResults(parameters))
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialSurveyEvalResults));
