import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Search from "../Utils/Search";
import { camera1688 } from "../Data/database";

const ProdEduCam = () => {

    return (
        <>
            <Container className="tab-title-main">
                <Row >
                    <Col sm={ 12 }>
                        <h4 className="section-title-prod-edu">1688 Camera </h4>
                        <h4 className="section-title-sub text-center">EXAMPLE SUBTITLE </h4>
                        <div className="text-center">
                            <img className="title-prod-tab " src="/images/tab-1688cam.png" alt="" />
                        </div>
                        <div className="line-blk"></div>
                        <p className="text-left">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aliquip ex ea commodo consequat.
                        </p>
                        <p className="text-left grey-lt">
                            #1688, #AIM, #Endoscopy, #1688AIMcamera, #ExampleTag, #Example_Tag
                        </p>
                        <hr className="mb-0" />
                        <Search />
                    </Col>
                </Row>
            </Container>

            <Container className="pb-80">
                <Row>
                    <Col sm={ 12 }>
                        <table className="table filelist">
                            <thead>
                                <tr>
                                    {/* <th className="cursor"><i className="las la-star star-no-circle cursor" /></th> */}
                                    <th className="sort-order cursor">SORT: A-Z <i className="las la-angle-down"></i></th>
                                </tr>
                            </thead>
                            <tbody className="table-sort">
                                { camera1688.map((item) => (
                                    <tr key={ item.id } >
                                        <td className="file-img">
                                            <img src={ item.thumbnail } alt="" />
                                            <div className="favorited">
                                                {/* <Favorite onFavorited={ () => onFavorited(item) } favorited={ item.favorited } /> */ }
                                            </div>
                                        </td>

                                        <td className="file-details">
                                            <Link to={ "/competitiveIntelSub/" + item.id }
                                                key={ item.id }
                                                value={ item.name }
                                            // onClick={ () => setViewFile(item) }
                                            >
                                                <ul className="">
                                                    <li>{ item.name }</li>
                                                    <li className="modified">
                                                        { item.modifiedDate } | <span><img className="cloud" src="images/icon-cloud-download.png" alt="" /> { item.downloaded }</span>
                                                        <span><img src="images/icon-arrow.jpg" className="angle" alt="" /></span>
                                                    </li>
                                                </ul>
                                            </Link>
                                        </td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                        <hr />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ProdEduCam;