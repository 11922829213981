import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import TableComIntel from '../Components/TableComIntel';
import Search from '../Utils/Search';
import { ListGroup } from '../Components/ListGroup';
import _ from 'lodash';
import ListUsingIndexedDB from '../Components/ListUsingIndexedDB/ListUsingIndexedDB';

import { useDispatch, useSelector } from 'react-redux';
import { getContentFolderListAction } from '../Store/actions/productEducationAction';
import LoadMoreButton from '../Components/LoadMoreButton/LoadMoreButton';
import useEffectAfterFirstRender from '../Hooks/useEffectAfterFirstRender';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import NoResults from '../Components/NoResults/NoResults';

const CompetitiveIntelFolderSub = () => {
	const { name } = useParams();
	const [ competitiveIntelFolder, setCompetitiveIntelFolder ] = useState([]);

	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);
	const [ searchingValue, setSearchingValue ] = useState('');

	const { id } = useParams();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getContentFolderListAction({ folderID: id, currentPageNumber: pageCounterForAPI }));
	}, []);

	const isFetchingContentFolderList = useSelector((state) => state.productEducation.isFetchingContentFolderList);
	const competitiveIntelFolderFromAPI = useSelector((state) => state.productEducation.competitiveIntelFolder);
	const isLastPageInCompetitiveIntelFolder = useSelector(
		(state) => state.productEducation.isLastPageInCompetitiveIntelFolder
	);
	const competitiveIntelFolderError = useSelector((state) => state.productEducation.competitiveIntelFolderError);

	useEffect(
		() => {
			setCompetitiveIntelFolder(competitiveIntelFolderFromAPI);
		},
		[ competitiveIntelFolderFromAPI ]
	);

	useEffectAfterFirstRender(
		() => {
			dispatch(getContentFolderListAction({ folderID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const handleLoadMore = () => {
		setSearchingValue('');
		setPageCounterForAPI((previousPageCounterForAPI) => previousPageCounterForAPI + 1);
	};

	// const categories = getCategories();
	const categories = [ { name: 'all files', id: 0 }, { name: name, id: 1 } ];

	const [ selectedCategory, setSelectedCategory ] = useState(categories[1]);
	const [ sortColumn, setSortColumn ] = useState({ path: 'name', order: 'asc' });

	const history = useHistory();
	const handleCategorySelect = (category) => {
		if (category.name.toLowerCase() === 'all files') history.goBack();
		setSelectedCategory(category);

		if (category.name.toLowerCase() === 'all files') history.goBack();
	};

	const handleSort = (path) => {
		if (sortColumn.path === path) {
			sortColumn.order === 'asc' ? setSortColumn({ path, order: 'desc' }) : setSortColumn({ path, order: 'asc' });
		} else {
			sortColumn.path = path;
			setSortColumn({ path, order: 'asc' });
		}
	};

	const filterCompetitiveIntelsBySearch = (ev) => {
		let filteredCompetitiveIntelsArray = competitiveIntelFolderFromAPI.filter((item) => {
			if (item.content_name.toLowerCase().includes(ev.currentTarget.value.toLowerCase().trim())) {
				return true;
			}

			return false;
		});

		setSearchingValue(ev.currentTarget.value);
		setCompetitiveIntelFolder(filteredCompetitiveIntelsArray);
	};

	const sortedCompetitiveIntels = _.orderBy(competitiveIntelFolder, [ sortColumn.path ], [ sortColumn.order ]);

	return (
		<React.Fragment>
			<Container className="tab-title-main">
				<h1 className="section-title">Competitive intel archive</h1>
				<div className="nav-pagelink">
					<ListGroup items={categories} onItemSelect={handleCategorySelect} selectedItem={selectedCategory} />
				</div>
				<div className="hr-bk-expand-ml-mr-15" />
				<Search searchingValue={searchingValue} handleFilter={filterCompetitiveIntelsBySearch} />
			</Container>
			{competitiveIntelFolderError ? <ErrorMessage message={competitiveIntelFolderError} /> : null}

			{competitiveIntelFolder.length === 0 && !isFetchingContentFolderList ? <NoResults /> : null}

			<ListUsingIndexedDB
				page="CI"
				list={sortedCompetitiveIntels}
				sortBy={'content_name'}
				sortAlphabeticallyByTitle={handleSort}
			/>

			{isFetchingContentFolderList ? <ComponentLoading message="Loading" removeHeight={true} /> : null}

			{!isLastPageInCompetitiveIntelFolder && !isFetchingContentFolderList ? (
				<LoadMoreButton handleClick={handleLoadMore} />
			) : null}
		</React.Fragment>
	);
};

export default CompetitiveIntelFolderSub;
