import React from 'react';
import classes from '../FormQuestions.module.css';

const TextResponse = ({ question, questionNumber, answeredQuestionsIDS, selectAnswer, selectedAnswer, containsError }) => {
    let answeredQuestion = answeredQuestionsIDS.filter(answeredQuestion => {
        return answeredQuestion.questionID === question.id;
    })[ 0 ]?.answer;

    const IS_READ_ONLY = answeredQuestionsIDS.length > 0;

    return (
        <div className={ [classes[ "form-survey__group" ], containsError ? classes['form_input_error'] :''].join(' ') }>
            <h3 className={ classes[ "form-survey__question" ] }>
                { questionNumber + 1 }. { question.questions } { question.is_required === "1" ? (<span>*</span>) : (null) }
            </h3>
            <div>
                <label
                    className={ classes[ "form-survey__option-label-textarea" ] }
                    htmlFor={ `response-${ question.id }` }></label>
                <textarea
                    className={ classes[ "form-survey__input-textarea" ]}
                    name={ `response-${ question.id }` }
                    id={ `response-${ question.id }` }
                    placeholder="response"
                    value={ answeredQuestion || selectedAnswer }
                    onChange={(ev) => selectAnswer(question.id, [ev.target.value] )}
                    readOnly={ IS_READ_ONLY }></textarea>
            </div>
        </div>
    )
}

export default TextResponse;