import React, { useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NavBar from '../Navi/NavBar';
import NavBarStryker from '../Navi/NavBarStryker';
import ProdEduCamera1688 from '../Pages/ProdEduCamera1688';
import Trials from '../Pages/Trials';
import TrialsFilloutChooseRespondent from '../Pages/TrialsFilloutChooseRespondent';
import TrialsSurveyUserQuestionare from '../Pages/TrialsSurveyUserQuestionare';
import TrialsOrganizationPeople from '../Pages/TrialsOrganizationPeople';
import TrialsOrganizationPeopleHistory from '../Pages/TrialsOrganizationPeopleHistory';
import TrialsOrganizationCases from '../Pages/TrialsOrganizationCases';
import TrialsTrialSummary from '../Pages/TrialsTrialSummary';
import PendingContent from '../Pages/PendingContent';

const DefaultContainer = () => {
	return (
		<div>
			<NavBar />
			<Switch>
				<Route exact path="/producteducation1688">
					<ProdEduCamera1688 />
				</Route>
				<Route exact path="/trials">
					<Trials />
				</Route>
				{/* crush trial progress */}
				<Route exact path="/trials/SurveysEvals/:name/Survey/respondent/:name">
					<TrialsFilloutChooseRespondent />
				</Route>
				{/* organization */}
				<Route path="/PendingContent">
					<PendingContent />
				</Route>
			</Switch>
		</div>
	);
};

export default DefaultContainer;
