import React, { useState } from 'react';
import classes from '../FormQuestions.module.css';

const MultipleChoiceQuestion = ({
	question,
	questionNumber,
	answeredQuestionsIDS = [],
	selectAnswer,
	isReadOnly,
	containsError
}) => {
	const IS_READ_ONLY = isReadOnly;

	const [ selectedOptions, setSelectedOptions ] = useState([]);

	const handleSelect = (optionId) => {
		let updatedSelection = selectedOptions;
		if (updatedSelection.includes(optionId)) {
			updatedSelection = updatedSelection.filter((option) => option !== optionId);
		} else if (selectedOptions.length > 0 && question.allow_multiple === '1') {
			updatedSelection = [ optionId ];
		} else {
			updatedSelection = [ ...updatedSelection, optionId ];
		}
		setSelectedOptions(updatedSelection);
		selectAnswer(question.id, updatedSelection);
	};

	return (
		<div className={[ classes['form-survey__group'], containsError ? classes['form_input_error'] : '' ].join(' ')}>
			<h3 className={classes['form-survey__question']}>
				{questionNumber + 1}. {question.questions} {question.is_required === '1' ? <span>*</span> : null}
			</h3>

			<div>
				{question.question_options.map((answer) => {
					let isAnswered =
						answeredQuestionsIDS.includes(answer.option_id) || selectedOptions.includes(answer.option_id);
					return (
						<div key={answer.option_id} className={classes['form-survey__option-vertical']}>
							<label
								className={classes['form-survey__option-label-vertical']}
								htmlFor={`answer-${answer.option_id}`}
							>
								{answer.options}
							</label>
							<input
								className={classes['form-survey__input-radio']}
								id={`answer-${answer.option_id}`}
								name={`option-${question.id}`}
								type="checkbox"
								checked={isAnswered}
								readOnly={IS_READ_ONLY}
								onClick={() => handleSelect(answer.option_id)}
							/>
							<label
								htmlFor={`answer-${answer.option_id}`}
								className={classes['form-survey__option-radio']}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MultipleChoiceQuestion;
