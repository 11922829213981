import React from 'react';
import { Row } from 'reactstrap';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';

export const StatsTrialsCases = (props) => {
	const { caseInformation, caseCount, specialtyCount } = props;

	return (
		<Row className="casestable-stats adj-m text-center">
			<div className="cases-stats">
				CASES
				<div className="number dkgrey">{caseCount}</div>
			</div>
			<div className="cases-stats">
				SPECIALTIES
				<div className="number dkgrey">{specialtyCount}</div>
			</div>
			<div className="line-blk-shrink mt-3" />

			<table className="table cases-table">
				<tbody className="cases-table">
					{caseInformation ? (
						caseInformation.map((item) => {
							return (
								<tr key={item.specialty_id}>
									<td className="icon-cases">
										<img src={item.icon} alt="" />
									</td>
									<td className="file-details">
										<ul className="case-info">
											<li className="case-no">{item.totalCase}</li>
											<li className="case-name">{item.specialtyName}</li>
										</ul>
									</td>
									<td className="percentage">
										<h2 className="case-info">
											{item.percentage}
											<span className="p-sign">%</span>
										</h2>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td>
								<ComponentLoading message="Loading..." />
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<div className="line-blk-shrink mt-3" />
		</Row>
	);
};

export default StatsTrialsCases;
