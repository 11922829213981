import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

const PAGE_LIMIT = 10;

export const getEvaluationsAction = ({ trialID, page }) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			page: page
		};

		dispatch({ type: ActionTypes.REQUEST_GET_EVALUATIONS });

		API()
			.post('/api/v7/evaluation/templatefillout', parameters)
			.then((res) => {
				if (res.data.success === '0')
					return dispatch({
						type: ActionTypes.GET_EVALUATIONS_ERROR,
						evaluationsError: res.data.message,
						evaluations: []
					});
				let evaluations = res.data.data;
				let pageLimitReached = false;
				if (evaluations && evaluations.length < PAGE_LIMIT) {
					pageLimitReached = true;
				}
				return dispatch({
					type: ActionTypes.GET_EVALUATIONS_SUCCESS,
					evaluations: evaluations,
					evaluationsPage: pageLimitReached ? page : page + 1,
					evaluationsPageLimitReached: pageLimitReached
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_EVALUATIONS_ERROR,
					evaluationsError: err.message
				});
			});
	};
};

export const getTemplatesAction = ({ trialID, page }) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			page: page
		};

		dispatch({ type: ActionTypes.REQUEST_GET_EVALUATION_TEMPLATES });

		API()
			.post('/api/v7/evaluation/MyTemplate', parameters)
			.then((res) => {
				if (res.data.success === '0')
					return dispatch({
						type: ActionTypes.GET_EVALUATION_TEMPLATES_ERROR,
						templatesError: res.data.message,
						templates: []
					});

				let templates = res.data.data;
				let pageLimitReached = false;
				if (templates && templates.length < PAGE_LIMIT) {
					pageLimitReached = true;
				}
				return dispatch({
					type: ActionTypes.GET_EVALUATION_TEMPLATES_SUCCESS,
					templates: templates,
					templatesPage: pageLimitReached ? page : page + 1,
					templatesPageLimitReached: pageLimitReached
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_EVALUATION_TEMPLATES_ERROR, templatesError: err.message });
			});
	};
};

export const getCrushTemplatesAction = ({ trialID, page }) => {
	let BU = JSON.parse(localStorage.getItem('BU'));
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			page: page,
			bu_id: BU
		};

		dispatch({ type: ActionTypes.REQUEST_GET_EVALUATION_CRUSH_TEMPLATES });

		API()
			.post('/api/v7/evaluation/crushtemplate', parameters)
			.then((res) => {
				if (res.data.message === 'No record found.')
					return dispatch({
						type: ActionTypes.GET_EVALUATION_CRUSH_TEMPLATES_ERROR,
						crushTemplatesError: res.data.message
					});

				let crushTemplates = res.data.data;
				let pageLimitReached = false;
				if (crushTemplates && crushTemplates.length < PAGE_LIMIT) {
					pageLimitReached = true;
				}
				return dispatch({
					type: ActionTypes.GET_EVALUATION_CRUSH_TEMPLATES_SUCCESS,
					crushTemplates: crushTemplates,
					crushTemplatesPage: pageLimitReached ? page : page + 1,
					crushTemplatesPageLimitReached: pageLimitReached
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_EVALUATION_CRUSH_TEMPLATES_ERROR,
					crushTemplatesError: err.message
				});
			});
	};
};

export const getResultsAction = (trialID) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID
		};

		API()
			.post('/api/v7/evaluation/ReviewResults', parameters)
			.then((res) => {
				if (res.data.message === 'No record found.')
					return dispatch({ type: ActionTypes.GET_TEMPLATES_ERROR, surveysAndEvaluations: res.data.message });
				return dispatch({ type: ActionTypes.GET_TEMPLATES_SUCCESS, templates: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_TEMPLATES_ERROR, templatesError: err.message });
			});
	};
};

export const getEvaluationTemplateAction = ({ trialID, templateID }) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			template_id: templateID
		};

		API()
			.post('/api/v7/evaluation/gettemplatedetails', parameters)
			.then((res) => {
				if (res.data.message === 'No record found.')
					return dispatch({
						type: ActionTypes.GET_EVALUATION_TEMPLATE_ERROR,
						selectedTemplateError: res.data.message
					});

				let template = res.data.data.template;
				return dispatch({
					type: ActionTypes.GET_EVALUATION_TEMPLATE_SUCCESS,
					selectedTemplate: template
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_EVALUATION_TEMPLATE_ERROR,
					selectedTemplateError: err.message
				});
			});
	};
};

export const getRespondentsForEvaluationAction = ({ trialID, templateID }) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			template_id: templateID
		};

		API()
			.post('/api/v7/evaluation/respondentlist', parameters)
			.then((res) => {
				return dispatch({
					type: ActionTypes.GET_EVALUATIONS_RESPONDENTS_SUCCESS,
					evaluationsRespondents: res.data.data || []
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_EVALUATIONS_RESPONDENTS_ERROR,
					evaluationsRespondentsError: err.message
				});
			});
	};
};

export const createEvaluationTemplateNameAction = (
	{ trialID, templateName, saveInto, templateID, questions },
	createQuestions
) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_name: templateName,
			template_id: templateID ? templateID : '',
			save_into: saveInto,
			questions:questions
		};

		API()
			.post('/api/v7/evaluation/createtemplate', parameters)
			.then((res) => {
				createQuestions(res.data.data.template_id);
				/* saveInto === 'trial'
					? dispatch(getEvaluationsAction({ trialID: trialID, page: '' }))
					: dispatch(getTemplatesAction({ trialID: trialID, page: '' })); */
				dispatch({
					type: ActionTypes.CREATE_EVALUATIONS_TEMPLATE_NAME_SUCCESS,
					createEvaluationsTemplateName: res.data.data
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.CREATE_EVALUATIONS_TEMPLATE_NAME_ERROR,
					createSEvaluationsTemplateNameError: err.message
				});
			});
	};
};

export const createEvaluationQuestionAction = (
	{
		trialID,
		templateID,
		requiredResponse,
		questionAnswers,
		questionType,
		question,
		allowMultipleResponses,
		allowNAResponse,
		minRatingLabel,
		maxRatingLabel,
		ratingScale,
		order
	},
	saveNextQuestion
) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			evaluation_template_id: templateID,
			is_required: requiredResponse,
			questions_type: questionType,
			questions: question,
			ordering: order
		};

		if (questionAnswers) {
			parameters['question_options'] = questionAnswers.map((qa) => {
				return { options: qa.options };
			});
		}

		if (allowMultipleResponses) {
			parameters['allow_multiple'] = allowMultipleResponses;
		}

		if (allowNAResponse) {
			parameters['include_na'] = allowNAResponse;
		}

		if (minRatingLabel) {
			parameters['min_rating_label'] = minRatingLabel;
		}

		if (maxRatingLabel) {
			parameters['max_rating_label'] = maxRatingLabel;
		}

		if (ratingScale) {
			parameters['rating_scale'] = ratingScale;
		}

		API()
			.post('/api/v7/evaluation/createquestion', parameters)
			.then((res) => {
				if (saveNextQuestion) {
					saveNextQuestion(templateID, order);
				}
				return dispatch({ type: ActionTypes.CREATE_EVALUATIONS_QUESTION_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.CREATE_EVALUATIONS_QUESTION_ERROR,
					createEvaluationsQuestionError: err.message
				});
			});
	};
};

export const editEvaluationQuestionAction = ({
	id,
	trialID,
	templateID,
	requiredResponse,
	questionAnswers,
	questionType,
	question,
	allowMultipleResponses,
	allowNAResponse,
	minRatingLabel,
	maxRatingLabel,
	ratingScale,
	order
}, saveNextQuestion) => {
	return (dispatch) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			evaluation_template_id: templateID,
			question_id: id,
			questions_type: questionType,
			questions: question,
			is_required: requiredResponse,
			ordering: order
		};

		if (questionAnswers) {
			parameters['question_options'] = questionAnswers;
		}

		if (allowMultipleResponses) {
			parameters['allow_multiple'] = allowMultipleResponses;
		}

		if (allowNAResponse) {
			parameters['include_na'] = allowNAResponse;
		}

		if (minRatingLabel) {
			parameters['min_rating_label'] = minRatingLabel;
		}

		if (maxRatingLabel) {
			parameters['max_rating_label'] = maxRatingLabel;
		}

		if (ratingScale) {
			parameters['rating_scale'] = ratingScale;
		}

		API()
			.post('/api/v7/evaluation/editquestion', parameters)
			.then((res) => {
				if (saveNextQuestion) {
					saveNextQuestion(order);
				}
				return dispatch({ type: ActionTypes.CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_ERROR,
					createSurveysAndEvaluationsQuestionError: err.message
				});
			});
	};
};

export const deleteEvaluationQuestionAction = ({ trialID, templateID, questionID, questionType }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			question_id: questionID,
			question_type: questionType
		};

		API()
			.post('/api/v7/evaluation/deletequestion', parameters)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const duplicateEvaluationAction = ({ trialID, templateID, templateName, saveInto }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			name: templateName,
			template_id: templateID,
			copy_into: saveInto
		};

		API()
			.post('/api/v7/evaluation/templateclone', parameters)
			.then((res) => {
				window.gtag('event', 'Copy to Trial', {
					event_category: 'Evaluation',
					event_label: templateName
				});
				window.location.href =
					res.data.success === '1' && saveInto === 'library'
						? `/trial/${trialID}/surveyevals/evaluation/templates/${res.data.data.id}`
						: `/trial/${trialID}/surveyevals/evaluation/${res.data.data.id}`;
				dispatch({ type: ActionTypes.DUPLICATE_EVALUATIONS_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.DUPLICATE_EVALUATIONS_ERROR,
					duplicateEvaluationError: err.message
				});
			});
	};
};

export const deleteEvaluationAction = ({ trialID, templateID }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID
		};

		API()
			.post('/api/v7/evaluation/templatedelete', parameters)
			.then((res) => {
				if (res.data.success === '1') window.location.replace(`/trial/${trialID}/surveyevals`);
				/* window.location.href = `/trial/${trialID}/surveyevals`;
				dispatch({ type: ActionTypes.DUPLICATE_EVALUATIONS_SUCCESS }); */
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.DUPLICATE_EVALUATIONS_ERROR,
					duplicateEvaluationError: err.message
				});
			});
	};
};

export const getCompletedEvaluationAction = ({ trialID, templateID, personID, personType }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			people_id: personID,
			people_type: personType
		};

		API()
			.post('/api/v7/evaluation/completedevaluation', parameters)
			.then((res) => {
				dispatch({ type: ActionTypes.GET_COMPLETED_EVALUATION_SUCCESS, completedEvaluation: res.data.data });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.GET_COMPLETED_EVALUATION_ERROR,
					completedEvaluationError: error.message
				});
			});
	};
};

export const submitEvaluationResponses = (
	{ trialID, templateID, peopleID, peopleType, filloutBy, signature, evaluationData, name },
	callback
) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			people_id: peopleID,
			people_type: peopleType,
			fill_out_by: filloutBy,
			evaluation_data: evaluationData
		};
		API()
			.post('/api/v7/evaluation/submitevaluation', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.gtag('event', 'Complete', {
						event_category: 'Evaluation',
						event_label: name
					});
					dispatch(
						submitEvaluationSignature(
							{
								trialID,
								templateID,
								signatureImage: signature,
								evaluationID: res.data.data.evaluation_id
							},
							callback
						)
					);
				}
			})
			.catch((error) => {});
	};
};

const submitEvaluationSignature = ({ trialID, templateID, signatureImage, evaluationID }, callback) => {
	return (dispatch) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		try {
			const formData = new FormData();
			formData.append('trial_id', trialID);
			formData.append('user_id', localStorage.getItem('user_id'));
			formData.append('template_id', templateID);
			formData.append('evaluation_id', evaluationID);
			formData.append('signature', signatureImage, 'signature.jpg');
			API()
				.post('/api/v7/evaluation/evaluationsignature', formData, config)
				.then((res) => {
					if (res.data.success) {
						callback && callback();
					}
				})
				.catch((error) => {});
		} catch (e) {
			console.log(e);
		}
	};
};

export const downloadBlankEvaluation = ({ trialID, templateID }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			people_id: 0,
			people_type: 'surgeon'
		};
		API()
			.post('/api/v7/evaluation/getblankpdf', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.open(res.data.data.PDFUrl, '_blank');
				}
			})
			.catch((error) => {});
	};
};

export const downloadIndividualResults = ({ trialID, templateID, peopleID, peopleType }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			people_id: peopleID,
			people_type: peopleType
		};
		API()
			.post('/api/v7/evaluation/getnewpdf', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.open(res.data.data.PDFUrl, '_blank');
				}
			})
			.catch((error) => {});
	};
};

export const getResultSummaryDetails = ({ trialID, templateID }) => {
	return (dispatch) => {
		const parameters = {
			template_id: templateID,
			trial_id: trialID,
			user_id: localStorage.getItem('user_id')
		};
		API()
			.post('/api/v7/evaluation/reviewresultdetail', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					return dispatch({
						type: ActionTypes.GET_EVALUATION_SUMMARY_SUCCESS,
						evaluationResultSummary: res.data.data
					});
				} else {
					return dispatch({
						type: ActionTypes.GET_EVALUATION_SUMMARY_SUCCESS,
						evaluationResultSummary: []
					});
				}
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_EVALUATION_SUMMARY_ERROR,
					evaluationResultSummaryError: err
				});
			});
	};
};

export const downloadPDFSummary = ({ trialID, templateID, questionIDs, questionChartImages }) => {
	questionIDs.forEach((qci) => {
		uploadChartImage({ questionId: qci, chartImage: questionChartImages[qci] });
	});
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			question_id: questionIDs.join(',')
		};
		API()
			.post('/api/v7/evaluation/getPDf', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.open(res.data.data.PDFUrl, '_blank');
				}
			})
			.catch((error) => {});
	};
};

export const downloadExcelSummary = ({ trialID, templateID, questionIDs }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			template_id: templateID,
			question_id: questionIDs
		};
		API()
			.post('/api/v7/evaluation/exportexcel', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.open(res.data.data.file_path, '_blank');
				}
			})
			.catch((error) => {});
	};
};

const saveStateToLocalStorage = () => {
	return (dispatch) => {};
};
const uploadChartImage = ({ questionId, chartImage }, callback) => {
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};
	try {
		const formData = new FormData();
		formData.append('question_id', questionId);
		formData.append('graph_image', dataURLtoBlob(chartImage), `question${questionId}-chart.jpg`);
		API()
			.post('/api/v7/evaluation/StoregraphImage', formData, config)
			.then((res) => {
				if (res.data.success) callback && callback();
			})
			.catch((error) => {});
	} catch (e) {
		console.log(e);
	}
};

function dataURLtoBlob(dataurl) {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([ u8arr ], { type: mime });
}
