import React from 'react';
import { Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ProfileImage from '../Utils/ProfileImage';
import getInitialsOfFirstAndLastName from '../Utils/getInitialsOfFirstAndLastName';

const TrialsSurveyResponses = (props) => {
	const { selectedRespondents } = props;
	const { trialID, type } = useParams();

	let path = window.location.pathname.endsWith('/')
		? window.location.pathname.substring(0, window.location.pathname.length - 1)
		: window.location.pathname;
	return (
		<Col>
			<div className="tab-rt-extend">
				<div className="tab-title">Responses</div>
				<div className="line-blk-shrink2" />
				<table className="table">
					<tbody>
						{selectedRespondents.map((item) => {
							return (
								item.general_status === '1' && (
									<tr key={item.id}>
										<td>
											<Link
												to={`/trial/${trialID}/surveyevals/${type}/${item.template_id}/review/person/${item.type}/${item.id}`}
												key={item.id}
												value={item.name}
											>
												<div className="person-details-container">
													<ProfileImage
														profile={{
															id: item.id,
															profileImage: getInitialsOfFirstAndLastName(item.name)
														}}
														cb={null}
														profileImageClasses="profile__image--list"
													/>
													<div className="person-details">
														<p className="card-list__name">
															{item.name.split(' ')[0]} {item.name.split(' ')[1]}
														</p>
														<p className="subtitle-tiny card-list__type">{item.title}</p>
														<p className="subtitle-tiny card-list__completed">COMPLETED</p>
													</div>
													<div>
														<i className="las la-angle-right" />
													</div>
												</div>
											</Link>
										</td>
									</tr>
								)
							);
						})}
					</tbody>
				</table>
				<div className="tab-button">
					<Link to={`${path}/respondents`}>VIEW ALL RESPONDENTS </Link>
				</div>
			</div>
		</Col>
	);
};

export default TrialsSurveyResponses;
