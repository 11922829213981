import React, { useState, useEffect } from "react";
import useEffectAfterFirstRender from '../Hooks/useEffectAfterFirstRender';
import LoadMoreButton from '../Components/LoadMoreButton/LoadMoreButton';

import { Container } from "reactstrap";
import TableComIntel from "../Components/TableComIntel";
import Search from "../Utils/Search";
import { getCategories } from "../Data/database";
import { ListGroup } from "../Components/ListGroup";
import _ from "lodash";

import ListUsingIndexedDB from '../Components/ListUsingIndexedDB/ListUsingIndexedDB';
import ErrorMessage from "../Components/ErrorMessage/ErrorMessage";
import NoResults from '../Components/NoResults/NoResults';

import { useDispatch, useSelector } from 'react-redux';
import {
  getContentListAction,
  getCompetitiveIntelSearchResultsAction,
  resetCompetitiveIntelFolderInReducerAction
} from '../Store/actions/productEducationAction';

const CompetitiveIntel = () => {
  // const intels = getIntels();

  const categories = getCategories();
  const [ selectedCategory, setSelectedCategory ] = useState(categories[ 0 ]);
  const [ sortColumn, setSortColumn ] = useState({ path: "name", order: "asc" });

  const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContentListAction(pageCounterForAPI));
  }, []);

  const isFetchingCompetitiveIntel = useSelector(state => state.productEducation.isFetchingContentList);
  const competitiveIntelArrayFromAPI = useSelector(state => state.productEducation.contentList);
  const sortedCompetitiveIntel = _.orderBy(competitiveIntelArrayFromAPI, [ sortColumn.path ], [ sortColumn.order ]);

  const competitiveIntelError = useSelector(state => state.productEducation.contentListError);
  const isLastPageInCompetitiveIntel = useSelector(state => state.productEducation.isLastPageInCompetitiveIntel);

  const [ isDownloadedView, setIsDownloadedView ] = useState(false);

  const handleCategorySelect = category => {
    setSelectedCategory(category);
    setSearchingValue('');
    if (category.name === 'downloaded')
    {
      setIsDownloadedView(true);
    } else
    {
      setIsDownloadedView(false);
    }
  };

  const handleSort = path => {
    if (sortColumn.path === path)
    {
      (sortColumn.order === "asc")
        ? setSortColumn({ path, order: "desc" })
        : setSortColumn({ path, order: "asc" });
    } else
    {
      sortColumn.path = path;
      setSortColumn({ path, order: "desc" })
    }
  }

  // const filtered = selectedCategory && selectedCategory.id
  //   ? intels.filter(i => i.category.id === selectedCategory.id)
  //   : intels;


  const [ searchingValue, setSearchingValue ] = useState('');
  const handleInputChange = (ev) => {
    setSearchingValue(ev.currentTarget.value)
  }

  const handleLoadMore = () => {
    setSearchingValue('');
    setPageCounterForAPI(previousPageCounterForAPI => previousPageCounterForAPI + 1);
  }

  useEffectAfterFirstRender(() => {
    dispatch(getContentListAction(pageCounterForAPI));
  }, [ pageCounterForAPI ]);

  useEffectAfterFirstRender(() => {
    if (!isDownloadedView)
    {
      dispatch(getCompetitiveIntelSearchResultsAction(searchingValue));
    }
  }, [ searchingValue ]);

  useEffect(() => {
    dispatch(resetCompetitiveIntelFolderInReducerAction());
  }, []);

  const isFetchingCompetitiveIntelSearchResults = useSelector(state => state.productEducation.isFetchingCompetitiveIntelSearchResults);
  const competitiveIntelSearchResults = useSelector(state => state.productEducation.competitiveIntelSearchResults);
  const sortedCompetitiveIntelSearchResults = _.orderBy(competitiveIntelSearchResults, [ sortColumn.path ], [ sortColumn.order ]);

  const competitiveIntelSearchResultsError = useSelector(state => state.productEducation.competitiveIntelSearchResultsError);

  let elementsToDisplay = null;
  if (isDownloadedView)
  {
    elementsToDisplay = (
      <ListUsingIndexedDB
        searchingValue={ searchingValue }
        isDownloadedView={ isDownloadedView } />
    );
  } else if (searchingValue === '')
  {
    elementsToDisplay = (
      <React.Fragment>
        <ListUsingIndexedDB
          page="CI"
          list={ sortedCompetitiveIntel }
          sortBy={ "content_name" }
          sortAlphabeticallyByTitle={ handleSort }
          isLoading={ isFetchingCompetitiveIntel } />

        { competitiveIntelError ? (
          <ErrorMessage message={ competitiveIntelError } />
        ) : (null) }

        { competitiveIntelArrayFromAPI.length === 0 && !isFetchingCompetitiveIntel ? (
          <NoResults />
        ) : (null) }

        { !isLastPageInCompetitiveIntel && !isFetchingCompetitiveIntel ? (
          <LoadMoreButton handleClick={ handleLoadMore } />
        ) : (null) }
      </React.Fragment>
    );
  } else
  {
    elementsToDisplay = (
      <React.Fragment>
        <ListUsingIndexedDB
          page="CI"
          list={ sortedCompetitiveIntelSearchResults }
          sortBy={ "content_name" }
          sortAlphabeticallyByTitle={ handleSort }
          isLoading={ isFetchingCompetitiveIntelSearchResults } />

        { competitiveIntelSearchResultsError && !isFetchingCompetitiveIntelSearchResults ? (
          <ErrorMessage message={ competitiveIntelSearchResultsError } />
        ) : (null) }

        { sortedCompetitiveIntelSearchResults.length === 0 && !isFetchingCompetitiveIntelSearchResults ? (
          <NoResults />
        ) : (null) }
      </React.Fragment>
    );
  }

  return (
    <>
      <Container className="tab-title-main">
        <h1 className="section-title">Competitive intel archive</h1>
        <div className="nav-pagelink">
          <ListGroup items={ categories } onItemSelect={ handleCategorySelect } selectedItem={ selectedCategory } />
        </div>
        <div className="hr-bk-expand-ml-mr-15" />
        <Search searchingValue={ searchingValue } handleInputChange={ handleInputChange } />
      </Container>

      { elementsToDisplay }
    </>
  );
}

export default CompetitiveIntel;