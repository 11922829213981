import React from 'react';
import { Container, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../assets/css/app.css"

const CrushToolkit = () => {

    return (
        <Container className="section">
            <Col sm={ 12 } className="tab-tabs">
                <div className="d-board-sect-title">
                    Crush toolkit
                    </div>
                <div className="toolkit">
                    <div className="d-board-item">
                        <Link to="/competitiveintel">
                            <div className="d-board-thumb intel" />
                            <div className="dashboard-item-name">COMPETITIVE INTEL</div>
                        </Link>
                    </div>
                    <div className="d-board-item">
                        <Link to="/talktracks/specialty">
                            <div className="d-board-thumb talk" />
                            <div className="dashboard-item-name">TALK TRACKS</div>
                        </Link>
                    </div>
                    <div className="d-board-item">
                        <Link to="/trials">
                            <div className="d-board-thumb trial" />
                            <div className="dashboard-item-name">TRIALS</div>
                        </Link>
                    </div>
                </div>
            </Col>
        </Container>
    );
}

export default CrushToolkit;
